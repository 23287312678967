import { useMemo } from 'react'
import { useAccount, useBalance, useWalletClient } from 'wagmi'

import { CHAINS } from '@/modules/tokenList/constants/chains'

import { TokenAddress } from '../types/token'
import { fixString, formatCurrency } from '../utils/formatCurrency'
import { useDynamicChain } from './useChain'
import useConnectionStatus from './useConnectionStatus'

export const useCommon = () => {
  const { data } = useWalletClient()
  const { isConnected } = useAccount()
  const isOnline = useConnectionStatus()

  const { chainId } = useDynamicChain()

  const tokenAddress: TokenAddress | undefined =
    typeof chainId !== 'undefined'
      ? CHAINS[chainId]['rpkTokenAddress']
      : undefined

  const {
    data: balanceData,
    refetch: refetchUserBalanceData,
    isLoading: isLoadingBalance,
  } = useBalance({
    chainId: data?.chain?.id,
    address: data?.account.address,
    token: tokenAddress,
    query: {
      enabled: isOnline && isConnected,
    },
  })

  const balance = useMemo(() => {
    return {
      formatted: formatCurrency(balanceData?.formatted || '0'),
      value: fixString(balanceData?.formatted || '0'),
      bigint: balanceData?.value || 0n,
      symbol: balanceData?.symbol || '',
    }
  }, [balanceData])

  return {
    balance,
    currentChain: data?.chain?.id,
    chainName: data?.chain?.name,
    refetchUserBalanceData,
    isLoadingBalance,
  }
}
