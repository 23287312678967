import { useQuery } from '@tanstack/react-query'

import { fetchQuote } from '@/core/services/client/backend/fetch-quote'
import { TokenAddress } from '@/core/types/token'
import { useToast } from '@/core/hooks/useToast'
import {
  debounceDependency,
  debounceHook,
} from '@/core/utils/hooks/debounceHook'

import { ChainId } from '@/modules/tokenList/constants/chains'

export type UseQuoteOptions = {
  chainId: ChainId
  amount: number
  fromTokenAddress: string | undefined
  toTokenAddress: string | undefined
  enabled?: boolean
}

const FETCH_STALE_TIME = 2 * 60 * 1000

export const useQuote = (options: UseQuoteOptions) => {
  const { enabled = true, ...queryOptions } = options

  const { toast } = useToast()

  const { chainId, fromTokenAddress, toTokenAddress, amount } = queryOptions

  return useQuery({
    queryKey: ['quote', chainId, fromTokenAddress, toTokenAddress, amount],
    staleTime: FETCH_STALE_TIME,
    queryFn: async () => {
      const { code, data, msg } = await fetchQuote({
        chainId,
        amount: amount,
        fromTokenAddress: fromTokenAddress as TokenAddress,
        toTokenAddress: toTokenAddress as TokenAddress,
      })

      // state error messages if any
      if (code !== '0') {
        toast({
          variant: 'destructive',
          title: 'Quote Error',
          description: msg || 'An error occurred',
        })
      }

      return data[0]
    },
    enabled: enabled && !!fromTokenAddress && !!toTokenAddress && amount > 0,
  })
}

export const useDebouncedQuote = debounceHook(useQuote, (options) => [
  options.fromTokenAddress,
  options.toTokenAddress,
  debounceDependency(options.amount, 500),
  options.chainId,
])
