import { makeLocalStorageHook } from '@/core/hooks/useStorage/makeStorageHook'

export const SLIPPAGES = [1, 5, 10, 20] as const
export const DEFAULT_SLIPPAGE: (typeof SLIPPAGES)[number] = 5
export const SLIPPAGE_PLACES = 1000 // To prevent rounding errors, we store slippage as an integer representing 0.1%

const useSlippageStorage = makeLocalStorageHook<number>(
  'APPVAR_slippage',
  DEFAULT_SLIPPAGE,
  {
    listen: false,
    serializer: (slippage) => slippage.toString(),
    deserializer: (s) => {
      const slippage = Math.round(Number(s))
      return isNaN(slippage)
        ? DEFAULT_SLIPPAGE
        : Math.min(Math.max(slippage, 0), 1000)
    },
  }
)

export type UseSlippageReturnType = {
  slippage: number
  setSlippage: (slippage: number) => void
  slippagePercent: number
  setSlippagePercent: (slippagePercent: number) => void

  // If you're doing computations with direct slippages (e.g. *100 or          /100, use slippageThousandth to prevent rounding errors)
  slippageThousandth: number
  setSlippageThousandth: (slippageToThousands: number) => void
}

export function useSlippage(): UseSlippageReturnType {
  const [slippageThousandth, setSlippageThousandth] = useSlippageStorage()
  return {
    slippage: slippageThousandth / SLIPPAGE_PLACES,
    setSlippage: (slippage) =>
      setSlippageThousandth(Math.round(slippage * SLIPPAGE_PLACES)),
    slippagePercent: (slippageThousandth * 100) / SLIPPAGE_PLACES,
    setSlippagePercent: (slippagePercent) =>
      setSlippageThousandth(
        Math.round((slippagePercent * SLIPPAGE_PLACES) / 100)
      ),
    slippageThousandth,
    setSlippageThousandth,
  }
}
