import { ZarklabToken } from '@/core/types/token'
import { InfiniteVisualizerOptions } from '@/core/hooks/useInfiniteVirtualizer'

const ROW_SIZE_MOBILE = 64
const ROW_SIZE_DESKTOP = 96
const ROW_GAP_MOBILE = 2
const ROW_GAP_DESKTOP = 8
const LOADING_ROW_HEIGHT = 60
const EMPTY_ROW_HEIGHT = 40
const ENDED_ROW_HEIGHT = 40
export function getInfiniteVirtualizerOptions(
  isMobile: boolean
): Omit<InfiniteVisualizerOptions<ZarklabToken, Window, Element>, 'fetcher'> {
  return {
    virtualizerOptions: {
      scrollMargin: 0,
      estimateSize: () => (!isMobile ? ROW_SIZE_MOBILE : ROW_SIZE_DESKTOP),
      overscan: 5,
      gap: isMobile ? ROW_GAP_MOBILE : ROW_GAP_DESKTOP,
    },
    footerElements: {
      loading: {
        size: LOADING_ROW_HEIGHT,
      },
      empty: {
        size: EMPTY_ROW_HEIGHT,
      },
      ended: {
        size: ENDED_ROW_HEIGHT,
      },
    },
  }
}
