import { createContext, ReactNode, RefObject, useContext, useRef } from 'react'
import { createPortal } from 'react-dom'

type SubheaderInfo = {
  containerRef: RefObject<Element>
}

// eslint-disable-next-line react-refresh/only-export-components
const SubheaderContext = createContext<SubheaderInfo | null>(null)
// eslint-disable-next-line react-refresh/only-export-components
const SubheaderContextProvider = SubheaderContext.Provider

export function useSubheader() {
  const subheaderInfo = useContext(SubheaderContext)

  return {
    createSubHeaderPortal: (elem: ReactNode) => {
      if (!subheaderInfo) {
        return null
      }
      const subheaderContainer = subheaderInfo.containerRef.current
      if (subheaderContainer === null) {
        return null
      }
      return createPortal(elem, subheaderContainer)
    },
    isReady:
      subheaderInfo !== null && subheaderInfo.containerRef.current !== null,
  }
}

export function useSubheaderSetup<Elem extends Element>() {
  const containerRef = useRef<Elem>(null)

  return {
    containerRef,
    SubheaderProvider: (props: { children: ReactNode }) => (
      <SubheaderContextProvider value={{ containerRef }}>
        {props.children}
      </SubheaderContextProvider>
    ),
  }
}
