import { OkxToken } from '@/core/services/client/okx/types'
import { TokenAddress } from '@/core/types/token'

import { useGetOkxTokenData } from '@/modules/tokenDetails/hooks/useGetOkxTokenData'
import { ChainId } from '@/modules/tokenList/constants/chains'

import { MAJOR_TOKENS } from './constants'

export function useMajorTokensDetails(chainId: ChainId) {
  const { getOkxTokenData, ...otherQueryProps } = useGetOkxTokenData(chainId)

  const tokenAddresses: TokenAddress[] =
    chainId in MAJOR_TOKENS
      ? MAJOR_TOKENS[chainId as keyof typeof MAJOR_TOKENS]
      : []
  const data = tokenAddresses
    .map((tokenAddress) => getOkxTokenData(tokenAddress))
    .filter((token) => !!token) as OkxToken[]

  return {
    data,
    ...otherQueryProps,
  }
}
