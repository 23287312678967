import { FC, ReactNode } from 'react'

interface GridProps {
  swap: ReactNode
  chart: ReactNode
  info: ReactNode
  stats: ReactNode
  signals: ReactNode
}

export const Grid: FC<GridProps> = ({ swap, chart, info, stats, signals }) => {
  return (
    <div className="grid md:grid-cols-3 gap-4 auto-rows-min w-full">
      <div className="md:col-span-2 ">{chart}</div>
      <div className="md:col-start-3 md:row-start-1">{swap}</div>
      <div className="md:col-start-2 md:row-start-2 ">{info}</div>
      <div className="md:col-start-1 md:row-start-2 row-start-4">{stats}</div>
      <div className="md:row-span-2 md:col-start-3 row-start-3">{signals}</div>
    </div>
  )
}
