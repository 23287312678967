import { TokenAddress } from '@/core/types/token'

import { appConfig } from '@/config'

export type Chain = {
  id: number
  label: string
  urlKey: string
  logoSrc: string
  spenderAddress: TokenAddress
  rpkTokenAddress: TokenAddress | undefined
}

export const CHAINS = {
  1: {
    id: 1,
    label: 'Ethereum',
    urlKey: 'ethereum',
    logoSrc: 'https://static.okx.com/cdn/wallet/logo/ETH-20220328.png',
    spenderAddress: '0x40aA958dd87FC8305b97f2BA922CDdCa374bcD7f',
    rpkTokenAddress: appConfig.ethereumTokenAddress,
  },
  137: {
    id: 137,
    label: 'Polygon',
    urlKey: 'polygon',
    logoSrc: 'https://static.okx.com/cdn/wallet/logo/MATIC-20220415.png',
    spenderAddress: '0x3B86917369B83a6892f553609F3c2F439C184e31',
    rpkTokenAddress: undefined,
  },
  42161: {
    id: 42161,
    label: 'Arbitrum',
    urlKey: 'arbitrum',
    logoSrc: 'https://static.okx.com/cdn/wallet/logo/arb_9000.png',
    spenderAddress: '0x70cBb871E8f30Fc8Ce23609E9E0Ea87B6b222F58',
    rpkTokenAddress: appConfig.arbitrumTokenAddress,
  },
  10: {
    id: 10,
    label: 'Optimism',
    urlKey: 'optimism',
    logoSrc: 'https://static.okx.com/cdn/wallet/logo/op_10000.png',
    spenderAddress: '0x68D6B739D2020067D1e2F713b999dA97E4d54812',
    rpkTokenAddress: undefined,
  },
  8453: {
    id: 8453,
    label: 'Base',
    urlKey: 'base',
    logoSrc: 'https://static.okx.com/cdn/wallet/logo/base_20900.png',
    spenderAddress: '0x57df6092665eb6058DE53939612413ff4B09114E',
    rpkTokenAddress: undefined,
  },
  1101: {
    id: 1101,
    label: 'Polygon zkEvm',
    urlKey: 'polygon-zkevm',
    logoSrc: 'https://static.okx.com/cdn/wallet/logo/POLYGON_ETH_19300.png',
    spenderAddress: '0x57df6092665eb6058DE53939612413ff4B09114E',
    rpkTokenAddress: undefined,
  },
  56: {
    id: 56,
    label: 'BNB Chain',
    urlKey: 'bnb-chain',
    logoSrc: 'https://static.okx.com/cdn/wallet/logo/BNB-20220308.png',
    spenderAddress: '0x2c34A2Fb1d0b4f55de51E1d0bDEfaDDce6b7cDD6',
    rpkTokenAddress: undefined,
  },
  9091: {
    id: 9091,
    label: 'Blast',
    urlKey: 'blast',
    logoSrc: 'https://static.okx.com/cdn/wallet/logo/blast_eth_23800.png',
    spenderAddress: '0x5fD2Dc91FF1dE7FF4AEB1CACeF8E9911bAAECa68',
    rpkTokenAddress: undefined,
  },
} as const satisfies Record<number, Chain>

export type ChainId = keyof typeof CHAINS
export type ChainLabel = (typeof CHAINS)[ChainId]['label']
export const DEFAULT_CHAIN_ID = CHAINS[1].id

const PREFERRED_CHAIN_DROPDOWN_ORDER: Array<Chain> = [
  CHAINS[1],
  CHAINS[42161],
  CHAINS[137],
  CHAINS[10],
]

export const CHAIN_DROPDOWN_ITEMS = (() => {
  const allChainIds = Object.keys(CHAINS).map((i) => parseInt(i, 10)) as Array<
    keyof typeof CHAINS
  >
  const listedIdsSet = new Set(
    PREFERRED_CHAIN_DROPDOWN_ORDER.map((chain) => chain.id)
  )
  const unlistedChains = allChainIds
    .filter((id) => !listedIdsSet.has(id))
    .map((id) => CHAINS[id])

  const chainOrder = [...PREFERRED_CHAIN_DROPDOWN_ORDER, ...unlistedChains]
  return chainOrder.map((chain) => ({
    value: chain.id,
    label: chain.label,
    url: chain.logoSrc,
  }))
})()

export function getChainIdFromUrlKey(urlKey: string) {
  const chain = Object.values(CHAINS).find((chain) => chain.urlKey === urlKey)
  return chain ? chain.id : undefined // Returns id if found, otherwise undefined
}
