import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { WagmiProvider } from 'wagmi'

import { Toaster } from '@/core/components/common/Toaster'
import { SafeAreaProvider } from './core/components/SafeAreaProvider'

import {
  persister,
  queryClient,
  rainbowKitTheme,
  wagmiProviderConfig,
} from './config'
import { router } from './router'

import '@rainbow-me/rainbowkit/styles.css'
import './assets/index.css'
import './services/amplify'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <WagmiProvider config={wagmiProviderConfig}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
      >
        <RainbowKitProvider theme={darkTheme(rainbowKitTheme)}>
          <SafeAreaProvider>
            <Toaster />
            <RouterProvider router={router} />
          </SafeAreaProvider>
        </RainbowKitProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-left"
        />
      </PersistQueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
)
