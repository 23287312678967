export const TOKEN_VIEW_TABS = [
  {
    id: 'all',
    label: 'All',
  },
  {
    id: 'hot',
    label: 'Hot',
  },
  {
    id: 'gainers',
    label: 'Gainers',
  },
  {
    id: 'new',
    label: 'New',
  },
  
] as const satisfies Array<{ id: string; label: string }>
export type TokenViewTab = (typeof TOKEN_VIEW_TABS)[number]['id']

export const DEFAULT_TOKEN_VIEW_TAB: TokenViewTab = 'all'
