import { useState } from 'react'

export const useTradingSignals = () => {
  const price = 1000 // this should come from token-details API
  const [isPaid, setIsPaid] = useState(false)
  const pay = async () => {
    setIsPaid(true)

    return await Promise.resolve({
      data: {
        message: 'Payment successful',
      },
    })
  }

  return {
    pay,
    price,
    isPaid,
  }
}
