import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { cx } from 'class-variance-authority'

import { TokenAddress } from '@/core/types/token'

import { TokenLabel } from './TokenLabel'

type SwapFormInputProps = {
  contractAddress: TokenAddress
  iconUrl: string
  currencyType: 'fromToken' | 'toToken'
  disabled?: boolean
  tokenValue: string
}

export const SwapFormInput: FC<SwapFormInputProps> = ({
  contractAddress,
  iconUrl,
  currencyType,
  disabled = false,
  tokenValue,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<{ fromToken: string; toToken: string }>()

  return (
    <div className="h-16 flex bg-[rgba(255,255,255,0.04)] border border-[rgba(255,255,255,0.12)] rounded-full justify-between items-center overflow-hidden px-4">
      <div className="flex flex-col items-start pl-1 flex-shrink min-w-16 max-w-32 overflow-hidden">
        <Controller
          control={control}
          name={currencyType}
          rules={{
            required: {
              value: true,
              message: 'A value is required',
            },
          }}
          render={({ field }) => (
            <input
              className={cx(
                'inline bg-transparent focus:outline-none text-white mr-4 mb-1',
                { '!border-b-[rgba(255,73,97,0.4)]': !!errors[currencyType] }
              )}
              type="number"
              placeholder="0.0"
              disabled={disabled}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <span className="text-xs text-[rgba(255,255,255,0.40)]">
          {tokenValue} USD
        </span>
      </div>
      <TokenLabel contractAddress={contractAddress} iconUrl={iconUrl} />
    </div>
  )
}
