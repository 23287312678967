import { FC } from 'react'

import Tabs from '@/core/components/common/Tabs'
import { SearchInput } from '@/core/components/SearchInput'

import { TOKEN_VIEW_TABS, TokenViewTab } from '../constants/tokens'

export interface TokenListPageHeaderProps {
  tokenViewTab: TokenViewTab
  onTokenTabChange: (tab: TokenViewTab) => void
  searchText: string
  onSearchTextChange: (query: string) => void
}

const TokenListPageHeader: FC<TokenListPageHeaderProps> = (props) => {
  const { tokenViewTab, onTokenTabChange, searchText, onSearchTextChange } =
    props
  return (
    <div className="w-full max-w-6xl mx-auto flex justify-between items-start px-4 py-3">
      <Tabs
        value={tokenViewTab}
        onChange={(tokenTab) => {
          onTokenTabChange(tokenTab as TokenViewTab)
        }}
        tabs={TOKEN_VIEW_TABS}
      />
      <SearchInput
        className="w-64"
        value={searchText}
        onChange={onSearchTextChange}
      />
    </div>
  )
}

export default TokenListPageHeader
