import { ComponentProps, CSSProperties, forwardRef } from 'react'
import GridLoader from 'react-spinners/GridLoader'

import tailwindConfig from '@/tailwind-config'

const COLORS = {
  primary: tailwindConfig.theme.colors.secondary[400],
  white: tailwindConfig.theme.colors.white,
} as const

const SIZES = {
  sm: 32,
  md: 64,
  lg: 96,
} as const

const SPINNER_CSS_RESET: CSSProperties = {
  whiteSpace: 'normal',
  lineHeight: 0,
}

const DEFAULT_COLOR: keyof typeof COLORS = 'primary'
const DEFAULT_SIZE: keyof typeof SIZES = 'sm'

export type SpinnerProps = {
  size?: keyof typeof SIZES
  color?: keyof typeof COLORS
  style?: CSSProperties
  className?: string
} & Omit<ComponentProps<typeof GridLoader>, 'color' | 'cssOverride'>

const Spinner = forwardRef<HTMLSpanElement, SpinnerProps>((props, ref) => {
  const {
    size = DEFAULT_SIZE,
    color = DEFAULT_COLOR,
    className,
    style,
    ...loaderProps
  } = props
  const orbSize = (SIZES[size] - 12) / 3
  return (
    <GridLoader
      ref={ref}
      cssOverride={{
        ...SPINNER_CSS_RESET,
        height: SIZES[size],
        ...style,
      }}
      className={className}
      color={COLORS[color]}
      size={orbSize}
      {...loaderProps}
    />
  )
})
Spinner.displayName = 'Spinner'

export default Spinner
