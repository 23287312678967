import { FC } from 'react'

import { ChainSelect } from './ChainSelect'
import { ConnectButton } from './ConnectButton'

export const Header: FC = () => {
  return (
    <div className="w-full max-w-6xl mx-auto flex justify-between items-start px-4 py-3">
      <div className="flex w-full justify-between items-center gap-2 md:gap-4 flex-wrap">
        <div className="flex w-96 items-center cursor-pointer gap-4">
          <ChainSelect />
        </div>

        <div className="flex justify-end gap-2 md:gap-4">
          <ConnectButton className="ml-0 md:ml-4" />
        </div>
      </div>
    </div>
  )
}
