import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { fetchTokenDetail } from '@/core/services/client/backend/fetch-token-detail'
import { OkxToken } from '@/core/services/client/okx/types'
import { TokenAddress } from '@/core/types/token'

import { ChainId } from '@/modules/tokenList/constants/chains'

import { useTokenData } from './useTokenData'

import { queryClient } from '@/config'

export const useTokenDetails = (chainId?: ChainId | undefined) => {
  const { contractAddress } = useParams()
  const { data: tokenData } = useTokenData(
    chainId as ChainId,
    contractAddress as TokenAddress
  )

  const { data } = useQuery({
    queryKey: ['tokenDetails', contractAddress],
    queryFn: async () => {
      if (typeof chainId === 'undefined') {
        throw Error('Chain ID is not defined')
      }

      const response = await fetchTokenDetail({
        chainIndex: chainId,
        tokenAddress: (tokenData as OkxToken).tokenContractAddress,
      })
      if (response.code !== '0') {
        throw Error(response.message)
      } else if (response.data.length === 0) {
        throw Error('No tokens found')
      }
      return response.data[0]
    },
    enabled: typeof chainId !== 'undefined' && !!tokenData,
  })

  useEffect(() => {
    // cleanup on unmount
    return () =>
      queryClient.removeQueries({ queryKey: ['tokenDetails', contractAddress] })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { tokenDetails: data }
}
