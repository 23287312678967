import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import colorAlpha from 'color-alpha'
import {
  AreaSeriesPartialOptions,
  ChartOptions,
  DeepPartial,
  LastPriceAnimationMode,
  LineStyle,
  Time,
} from 'lightweight-charts'

import { TokenAddress } from '@/core/types/token'

import { dayData, monthData, weekData, yearData } from './mock'

import tailwindConfig from '@/tailwind-config'

const config: DeepPartial<ChartOptions> = {
  autoSize: true,
  layout: {
    background: { color: 'transparent' },
    textColor: tailwindConfig.theme.colors['cool-gray'][50],
  },
  grid: {
    vertLines: { visible: false },
    horzLines: { visible: false },
  },
  timeScale: {
    borderVisible: false,
  },
  rightPriceScale: {
    borderVisible: false,
    visible: true,
    scaleMargins: {
      top: 0.25,
    },
    entireTextOnly: true,
  },
  crosshair: {
    horzLine: {
      visible: true,
      labelVisible: true,
      labelBackgroundColor: tailwindConfig.theme.colors.secondary[600],
    },
    vertLine: {
      labelVisible: false,
      style: LineStyle.Solid,
      width: 1,
      visible: false,
    },
  },
  handleScale: false,
  handleScroll: false,
}

const areaSeriesConfig: AreaSeriesPartialOptions = {
  lineColor: tailwindConfig.theme.colors.secondary[400],
  topColor: colorAlpha(tailwindConfig.theme.colors.secondary[400], 0.25),
  bottomColor: colorAlpha(tailwindConfig.theme.colors.secondary[400], 0),
  lastPriceAnimation: LastPriceAnimationMode.Continuous,
  priceLineVisible: false,
  priceLineColor: tailwindConfig.theme.colors.green[700],
  priceLineStyle: LineStyle.Dashed,
}

export interface IntervalColors {
  '1D': string
  '1W': string
  '1M': string
  '1Y': string
}

const intervals: (keyof IntervalColors)[] = ['1D', '1W', '1M', '1Y']

const intervalColors: IntervalColors = {
  '1D': '#2962FF',
  '1W': 'rgb(225, 87, 90)',
  '1M': 'rgb(242, 142, 44)',
  '1Y': 'rgb(164, 89, 209)',
}

const seriesData = new Map([
  ['1D', dayData],
  ['1W', weekData],
  ['1M', monthData],
  ['1Y', yearData],
])

interface Response {
  time: Time
  value: number
}

export const useChart = ({
  contractAddress,
}: {
  contractAddress?: TokenAddress
}) => {
  const [dateInterval, setDateInterval] = useState<keyof IntervalColors>('1D')

  const { data, refetch } = useQuery<Response[], Error>({
    queryKey: ['chart', contractAddress, dateInterval],
    queryFn: () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (dateInterval === '1D') {
            resolve(dayData)
          } else if (dateInterval === '1W') {
            resolve(weekData)
          } else if (dateInterval === '1M') {
            resolve(monthData)
          } else {
            resolve(yearData)
          }
        }, 300) // Simulating 1 second delay
      })
    },
  })

  const onChangeInterval = (interval: keyof IntervalColors) => {
    setDateInterval(interval)
    refetch()
  }

  return {
    data: data || [],
    config,
    intervals,
    dateInterval,
    areaSeriesConfig,
    onChangeInterval,
    intervalColors,
    seriesData,
  }
}
