import { createContext, ReactNode, useState } from 'react'

type SelectedToken = { to: string } | { from: string }

type SelectedTokenContextType = {
  selectedToken: SelectedToken
  setSelectedTokenAddress: (address: string) => void
  swapTokenAddresses: (address?: string | undefined) => void
}

const SelectedTokenContext = createContext<SelectedTokenContextType>({
  selectedToken: { to: '' },
  setSelectedTokenAddress: () => undefined,
  swapTokenAddresses: () => undefined,
})

const SelectedTokenProvider = ({ children }: { children: ReactNode }) => {
  const [selectedToken, setSelectedToken] = useState<SelectedToken>({ to: '' })

  const setSelectedTokenAddress = (address: string) =>
    setSelectedToken(
      'from' in selectedToken ? { from: address } : { to: address }
    )

  const swapTokenAddresses = (address: string) =>
    setSelectedToken(
      'from' in selectedToken ? { to: address } : { from: address }
    )

  return (
    <SelectedTokenContext.Provider
      value={{
        selectedToken,
        setSelectedTokenAddress,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        swapTokenAddresses: swapTokenAddresses as any,
      }}
    >
      {children}
    </SelectedTokenContext.Provider>
  )
}

export { SelectedTokenContext, SelectedTokenProvider }
