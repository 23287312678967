import API from '../../api'

// [2024-11-11] Currently a backend proxy of the OKX endpoint at
// https://www.okx.com/web3/build/docs/waas/dex-approve-transaction

export type FetchApproveTransactionOptions = {
  chainId: number
  tokenContractAddress: `0x${string}`
  approveAmount: string // The amount of token that needs to be permitted (set in minimal divisible units, e.g., 1.00 USDT set as 1000000, 1.00 DAI set as 1000000000000000000)
}
export type FetchApproveTransactionResponse = {
  data: `0x${string}` // Call data
  dexContractAddress: `0x${string}` // The contract address of OKX DEX approve
  gasLimit: string
  gasPrice: string // Gas price in wei
}

export async function fetchApproveTransaction(
  options: FetchApproveTransactionOptions
): Promise<FetchApproveTransactionResponse> {
  const { chainId, tokenContractAddress, approveAmount } = options
  const response = await API.get('/dex/approve-transaction', {
    chainId: String(chainId),
    tokenContractAddress,
    approveAmount: approveAmount,
  })
  return response.data[0]
}
