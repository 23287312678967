import { FC } from 'react'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/core/components/common/Tooltip'
import { useChainFromRoute } from '@/core/hooks/useChain'

export const ChainIndicator: FC = () => {
  const { chain } = useChainFromRoute()

  if (!chain) {
    return null
  }

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger className="aspect-square h-11 rounded-full bg-[#222730] hover:bg-[#31353D] transition duration-200 ease-in-out flex items-center justify-center p-2 overflow-hidden">
          <img src={chain.logoSrc} />
        </TooltipTrigger>
        <TooltipContent>
          <p>{chain.label}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
