import { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { TokenAddress } from '@/core/types/token'

import { SelectedTokenContext } from '../components/swap/SelectedTokenContext'

export const useTokenSearchParams = () => {
  const { contractAddress } = useParams<{ contractAddress: TokenAddress }>()

  const { selectedToken } = useContext(SelectedTokenContext)

  const from = (
    selectedToken && 'from' in selectedToken
      ? selectedToken.from
      : contractAddress
  ) as TokenAddress
  const to = (
    selectedToken && 'to' in selectedToken ? selectedToken.to : contractAddress
  ) as TokenAddress

  return { from, to }
}
