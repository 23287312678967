import { Auth } from 'aws-amplify'

import { TokenAddress } from '@/core/types/token'
import { getRandomString } from '@/core/utils'

import { appConfig } from '@/config'

export interface ICognitoUser {
  username: string
  challengeParam: {
    message: string
  }
}

export enum AuthError {
  UserNotFound = 'UserNotFoundException',
}

export const handleSignIn = async ({
  address,
}: {
  address: TokenAddress
}): Promise<ICognitoUser> => {
  try {
    const cognitoUser = await Auth.signIn(address)
    console.log(
      'Amplify signIn successful. Proceeding to Custom Auth challenge.'
    )
    return cognitoUser
  } catch (error) {
    if (error?.toString().includes(AuthError.UserNotFound)) {
      const params = {
        username: address,
        password: `${getRandomString(30)}`,
      }

      await Auth.signUp(params)
      console.log('Amplify SignUp successful', params)
    } else {
      console.error('Amplify SignUp error', error)
      throw error
    }

    return await handleSignIn({ address })
  }
}

export const authenticateUser = async (): Promise<ICognitoUser | null> => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user
  } catch (error) {
    console.error('Amplify authenticated user failed', error)
    return null
  }
}

export const signUserOut = async () => {
  try {
    await Auth.signOut()
  } catch (error) {
    console.error('Amplify error signing out', error)
  }
}

export const getCognitoUserName = () => {
  try {
    const awsCtKey = `CognitoIdentityServiceProvider.${appConfig.userPoolWebClientId}.LastAuthUser`
    const username = localStorage.getItem(awsCtKey)
    return username ? username : null
  } catch (error) {
    console.error('Get localStorage CognitoUserName error:', error)
    return null
  }
}
