import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { TokenAddress } from '@/core/types/token'
import { useChainFromRoute } from '@/core/hooks/useChain'

import { useDebouncedQuote } from '@/modules/tokenDetails/hooks/useQuote'
import { useTokenData } from '@/modules/tokenDetails/hooks/useTokenData'
import { ChainId } from '@/modules/tokenList/constants/chains'

import { SelectedTokenContext } from '../components/swap/SelectedTokenContext'
import { useTokenDetails } from './useTokenDetails'

export interface SwapFormValues {
  fromToken: string
  toToken: string
  slippage: number
}

export const useSwapForm = ({
  from,
  to,
}: {
  from: TokenAddress | undefined
  to: TokenAddress | undefined
}) => {
  const { swapTokenAddresses } = useContext(SelectedTokenContext)

  const methods = useForm<SwapFormValues>({
    defaultValues: { fromToken: '', toToken: '' },
  })

  const { watch, setValue } = methods

  const fromTokenInput = Number(watch('fromToken'))
  const toTokenInput = Number(watch('toToken'))

  const { chainId } = useChainFromRoute()

  const { data: fromToken } = useTokenData(
    chainId as ChainId,
    from as `0x${string}`
  )
  const { data: toToken } = useTokenData(
    chainId as ChainId,
    to as `0x${string}`
  )

  const { tokenDetails } = useTokenDetails(chainId)
  const fromTokenDecimalPlaces = Number(tokenDetails?.decimals)

  const getAmount = useMemo(
    () => fromTokenInput * 10 ** fromTokenDecimalPlaces,
    [fromTokenDecimalPlaces, fromTokenInput]
  )

  const {
    data: quote,
    isLoading: isQuoteLoading,
    isPending: isQuotePending,
  } = useDebouncedQuote({
    chainId: chainId as ChainId,
    fromTokenAddress: fromToken?.tokenContractAddress,
    toTokenAddress: toToken?.tokenContractAddress,
    amount: getAmount,
  })

  const { fromToken: fromTokenData, toToken: toTokenData } = quote ?? {}

  useEffect(() => {
    if (!quote) {
      setValue('toToken', '')
      return
    }

    const { toTokenAmount: rawToTokenAmount, toToken } = quote
    const toTokenDecimalPlaces = Number(toToken?.decimal)
    const formattedToTokenAmount =
      Number(rawToTokenAmount) / 10 ** toTokenDecimalPlaces

    setValue('toToken', String(formattedToTokenAmount))
  }, [quote, fromTokenInput, setValue])

  const handleSwapInputs = () => {
    swapTokenAddresses()

    setValue('fromToken', String(toTokenInput))
    setValue('toToken', '')
  }

  const onSuccessfulTransaction = useCallback(() => {
    setValue('fromToken', '')
    setValue('toToken', '')
  }, [setValue])

  const fromTokenLogoUrl = fromToken?.tokenLogoUrl
  const toTokenLogoUrl = toToken?.tokenLogoUrl

  const fromTokenValue = (
    fromTokenInput * Number(fromTokenData?.tokenUnitPrice ?? 0)
  ).toFixed(2)

  const toTokenValue = (
    toTokenInput * Number(toTokenData?.tokenUnitPrice ?? 0)
  ).toFixed(2)

  return {
    methods,
    fromTokenInput,
    toTokenInput,
    handleSwapInputs,
    onSuccessfulTransaction,
    quote,
    isQuotePending,
    isQuoteLoading,
    fromTokenLogoUrl,
    toTokenLogoUrl,
    fromTokenValue,
    toTokenValue,
  }
}
