import { TokenAddress } from '@/core/types/token'
import { useOkxTokens } from '@/core/hooks/useOkxTokens'

import { ChainId } from '@/modules/tokenList/constants/chains'

export const useTokenData = (
  chainId: ChainId,
  contractAddress: TokenAddress
) => {
  const { data: okxTokens, ...otherQueryProps } = useOkxTokens(chainId)

  const data = okxTokens?.find(
    (token) => token.tokenContractAddress === contractAddress
  )

  return { data, ...otherQueryProps }
}
