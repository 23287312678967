import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { TokenAddress } from '@/core/types/token'
import { useChainFromRoute } from '@/core/hooks/useChain'
import { cn } from '@/core/utils'

import { ChainId } from '@/modules/tokenList/constants/chains'

import { useMajorTokensDetails } from './hooks'

type MajorTokensSelectProps = {
  onChange?: (contractAddress: TokenAddress) => void
  onLoaded?: () => void
}

export const MajorTokensSelect: FC<MajorTokensSelectProps> = (props) => {
  const { onChange, onLoaded } = props

  const { chainId } = useChainFromRoute()

  const { contractAddress: currentTokenAddress } = useParams()

  const { isLoading, data } = useMajorTokensDetails(chainId as ChainId)

  useEffect(() => {
    if (isLoading) {
      onLoaded?.()
    }
  }, [isLoading, onLoaded])

  if (isLoading) {
    return null
  }

  return (
    <div className="flex gap-1 px-2">
      {data
        .filter((token) => token.tokenContractAddress !== currentTokenAddress)
        .map((token) => (
          <button
            key={token.tokenContractAddress}
            className={cn(
              'flex gap-2 items-center',
              'rounded-full px-2 py-1 text-xs md:text-sm',
              'bg- text-white text-primary-foreground shadow bg-[#222730] hover:bg-[#31353D] duration-200 ease-in-out'
            )}
            onClick={() => onChange?.(token.tokenContractAddress)}
          >
            <img src={token.tokenLogoUrl} className="w-5" />
            {token.tokenSymbol}
          </button>
        ))}
    </div>
  )
}
