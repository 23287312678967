import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { type VariantProps } from 'class-variance-authority'

import { cn } from '@/core/utils/classNames'

import { ButtonStyleProps, buttonVariants } from './Button-constants'

export type ButtonLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  VariantProps<typeof buttonVariants> &
  ButtonStyleProps & {
    asChild?: boolean
    children: React.ReactNode | string
  }

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    { className, variant = 'default', size, asChild = false, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'a'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
ButtonLink.displayName = 'ButtonLink'

export { ButtonLink as ButtonLink }
