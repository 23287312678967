import { FC } from 'react'

import { BackButton } from '@/core/components/BackButton'

import { Chart } from '../components/chart/Chart'
import { Grid } from '../components/Grid'
import { TradingSignalsCard } from '../components/signals/TradingSignalsCard'
import { Stats } from '../components/stats/Stats'
import { SwapFormWithProvider } from '../components/swap/SwapFormWithProvider'
import { TokenInfo } from '../components/tokenInfo/TokenInfo'

export const TokenDetails: FC = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full my-3 md:my-1">
        <BackButton />
      </div>

      <Grid
        swap={<SwapFormWithProvider />}
        chart={<Chart />}
        stats={<Stats />}
        info={<TokenInfo />}
        signals={<TradingSignalsCard />}
      />
    </div>
  )
}
