import { useAccount, useSwitchChain } from 'wagmi'

import { useDynamicChain } from './useChain'

export const useSynchronizeChain = () => {
  const { chainId: walletChainId } = useAccount()
  const { chainId: dexChainId } = useDynamicChain()

  const { switchChainAsync } = useSwitchChain()

  const synchronizeChain = async () => {
    if (typeof dexChainId === 'undefined') {
      return
    } else if (walletChainId === dexChainId) {
      return
    }
    await switchChainAsync({ chainId: dexChainId })
  }

  return { synchronizeChain }
}
