import {
  StorageHookReturnType,
  UseStorageNonStringOptions,
  UseStorageOptions,
} from './makeStorageHookConstructor'
import { useLocalStorage, useSessionStorage } from './storageHooks'

type MakeStorageHookReturnType<T> = () => StorageHookReturnType<T>

export function makeLocalStorageHook<T extends string>(
  key: string,
  initialValue?: T,
  options?: UseStorageOptions<T>
): MakeStorageHookReturnType<T>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeLocalStorageHook<T extends Exclude<any, string>>(
  key: string,
  initialValue: T,
  options: UseStorageNonStringOptions<T>
): MakeStorageHookReturnType<T>
export function makeLocalStorageHook<T>(
  key: string,
  initialValue: T = '' as T,
  options?: UseStorageOptions<T>
) {
  return () =>
    useLocalStorage<T>(
      key,
      initialValue,
      options as UseStorageNonStringOptions<T>
    )
}

export function makeSessionStorageHook<T extends string>(
  key: string,
  initialValue?: T,
  options?: UseStorageOptions<T>
): MakeStorageHookReturnType<T>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeSessionStorageHook<T extends Exclude<any, string>>(
  key: string,
  initialValue: T,
  options: UseStorageNonStringOptions<T>
): MakeStorageHookReturnType<T>
export function makeSessionStorageHook<T>(
  key: string,
  initialValue: T = '' as T,
  options?: UseStorageOptions<T>
) {
  return () =>
    useSessionStorage<T>(
      key,
      initialValue,
      options as UseStorageNonStringOptions<T>
    )
}
