/** @type {import('tailwindcss').Config} */

export default {
  content: ['./src/**/*.{js,jsx,ts,tsx,vue}', './index.html'],
  darkMode: 'media',
  theme: {
    fontFamily: {
      robotomedium: ['Roboto-Medium', 'sans-serif'],
    },
    extend: {
      colors: {
        transparent: 'transparent',
        current: 'currentColor',
        white: '#fff',
        gold: {
          DEFAULT: '#FCCF58',
          500: '#F6CD64',
          600: '#F0BC56',
          800: '#EAAD48',
        },
        silver: {
          DEFAULT: '#D1D1D1',
          500: '#A4B1BF',
          600: '#99A5B6',
          800: '#8D99A4',
        },
        bronze: {
          DEFAULT: '#E19864',
          500: '#A07C71',
          600: '#986E67',
          800: '#8F6C61',
        },
        diamond: '#7B9FC2',
        danger: '#ff4961',
        success: '#00C080',
        growth: '#1DEE6A',
        loss: '#EE6A1D',
        'signal-red': '#EC5E57',
        'signal-purple': '#A704F6',
        'signal-yellow': '#FFE660',
        'system-blue': '#2858C0',
        'hexagon-outline-purple': '#9677E6',
        'hexagon-fill-purple': '#2D2640',
        purple: {
          DEFAULT: '#7000FF',
          100: '#7000FF',
          200: '#9603F1',
          300: '#6E0BB0',
          400: '#27194A',
        },
        arbitrum: '#28a0f0',
        primary: {
          DEFAULT: '#CCFF00',
          50: '#FAFFE5',
          100: '#F5FFCC',
          200: '#EBFF99',
          300: '#E0FF66',
          400: '#D6FF33',
          500: '#CCFF00',
          600: '#A3CC00',
          700: '#7A9900',
          800: '#526600',
          900: '#293300',
          950: '#141A00',
        },
        secondary: {
          DEFAULT: '#8162D0',
          50: '#EFEBF9',
          100: '#E0D8F3',
          200: '#C0B1E7',
          300: '#A189DC',
          400: '#8162D0',
          500: '#8162D0',
          600: '#4E2F9D',
          700: '#3B2376',
          800: '#27184E',
          900: '#140C27',
          950: '#0A0614',
        },
        tertiary: {
          DEFAULT: '#13D7C0',
          50: '#E8FDFA',
          100: '#D0FBF6',
          200: '#A1F7ED',
          300: '#72F3E4',
          400: '#44EEDB',
          500: '#13D7C0',
          600: '#11BBA8',
          700: '#0C8D7E',
          800: '#085E54',
          900: '#042F2A',
          950: '#021715',
          951: '#5260ff',
        },
        'cool-gray': {
          DEFAULT: '#4A5E80',
          50: '#EFF1F6',
          100: '#DFE4EC',
          200: '#BEC8DA',
          300: '#9EADC7',
          400: '#7E92B4',
          500: '#4A5E80',
          600: '#4B5F81',
          700: '#384761',
          800: '#252F41',
          850: '#1d2330',
          900: '#131820',
          950: '#090C10',
        },
        gray: {
          DEFAULT: '#808080',
          50: '#F2F2F2',
          100: '#E6E6E6',
          200: '#CCCCCC',
          300: '#B3B3B3',
          350: '#a4a3a6',
          400: '#999999',
          500: '#808080',
          550: '#79797e',
          600: '#666666',
          700: '#4D4D4D',
          800: '#333333',
          850: '#1f1f26',
          900: '#1A1A1A',
          950: '#0D0D0D',
        },
      },
      borderRadius: {
        DEFAULT: '0.1875rem',
      },
      fontSize: {
        xxs: '.625rem',
        huge: '5.625rem',
      },
      keyframes: {
        fadeIn: {
          '0%': { opacity: 0 },
          '66%': { opacity: 0 },
          '100%': { opacity: 1 },
        },
        immediateFadeIn: {
          '0%': { opacity: 0 },
          '100%': { opacity: 1 },
        },
        heartbeat: {
          '0%': {
            transform: 'scale(0.5)',
            opacity: '0',
          },
          '15%': {
            transform: 'scale(1.1)',
            opacity: '1',
          },
          '25%': {
            transform: 'scale(0.9)',
            opacity: '1',
          },
          '35%': {
            transform: 'scale(1.1)',
            opacity: '1',
          },
          '45%': {
            transform: 'scale(0.9)',
            opacity: '1',
          },
          '55%': {
            transform: 'scale(0.95)',
            opacity: '1',
          },
          '80%': {
            transform: 'scale(1)',
            opacity: '1',
          },
          '100%': {
            transform: 'scale(0.1)',
            opacity: '0',
          },
        },
        pulse: {
          '0%, 100%': { opacity: 1 },
          '50%': { opacity: 0.5 },
        },
        backgroundLinear: {
          '0%': { backgroundPosition: '0% 50%' },
          '100%': { backgroundPosition: '200% 50%' },
        },
        rotateHourglass: {
          '90%': { transform: 'rotate(0deg)' },
          '100%': { transform: 'rotate(180deg)' },
        },
        backgroundLinearSkew: {
          '0%': { backgroundPosition: '200% 0%' },
          '100%': { backgroundPosition: '-200% 0%' },
        },
      },
      boxShadow: {
        button: '0rem 4rem 0.625rem rgba(0, 0, 0, 0.15)',
      },
      width: {
        xs: '5.5rem',
        sm: '8.5rem',
        md: '10rem',
        lg: '10.625rem',
      },
      maxWidth: {
        ['btn-md']: '14rem',
      },
      spacing: {
        bnr: '43.59%',
      },
    },
    screens: {
      xs: '384px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
  },
  variants: {
    extend: {},
  },
}
