import { CSSProperties, forwardRef } from 'react'

import { cn } from '../utils'
import Spinner, { SpinnerProps } from './common/Spinner'

type SpinnerBlockProps = {
  style?: CSSProperties
  className?: string
  spinnerStyle?: SpinnerProps['style']
  spinnerClassName?: SpinnerProps['className']
} & Omit<SpinnerProps, 'style' | 'className'>

const SpinnerBlock = forwardRef<HTMLDivElement, SpinnerBlockProps>(
  (props, ref) => {
    const {
      style,
      className,
      spinnerStyle,
      spinnerClassName,
      ...spinnerProps
    } = props
    return (
      <div
        ref={ref}
        className={cn('p-4 flex place-items-center justify-center', className)}
        style={style}
      >
        <Spinner
          style={spinnerStyle}
          className={spinnerClassName}
          {...spinnerProps}
        />
      </div>
    )
  }
)
SpinnerBlock.displayName = 'Spinner'

export default SpinnerBlock
