import API from '../../api'

// [2024-11-11] Currently a backend proxy of the OKX endpoint at
// https://www.okx.com/web3/build/docs/waas/walletapi-api-token-detail

export type FetchTokenDetailsOptions = {
  chainIndex: number
  tokenAddress?: string
}

export type FetchTokenDetailsResponseSocialMedia =
  | 'twitter'
  | 'facebook'
  | 'reddit'
  | 'messageboard'
  | 'chat'
  | 'github'
  | 'whitepaper'
  | 'announcement'
  | 'telegram'

export type FetchTokenDetailsResponse = {
  code: string
  message: string
  data: Array<{
    logoUrl: string
    officialWebsite: string
    socialUrls: Record<FetchTokenDetailsResponseSocialMedia, string[]>
    decimals: string
    tokenAddress: string
    chainIndex: string
    chairName: string
    symbol: string
    maxSupply: string
    totalSupply: string
    volume24h: string
    marketCap: string
  }>
}
export async function fetchTokenDetail(
  options: FetchTokenDetailsOptions
): Promise<FetchTokenDetailsResponse> {
  const { chainIndex, tokenAddress } = options

  return await API.get(`/dex/token-detail`, {
    chainIndex: String(chainIndex),
    ...(tokenAddress && { tokenAddress }),
  })
}
