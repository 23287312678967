import { FC, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { TokenAddress } from '@/core/types/token'
import { useChainFromRoute } from '@/core/hooks/useChain'
import { useRecentTokens } from '@/core/hooks/useRecentTokens'
import { cn } from '@/core/utils/classNames'

import { ChainId } from '@/modules/tokenList/constants/chains'

import { useTokenData } from '../../hooks/useTokenData'
import { SelectedTokenContext } from './SelectedTokenContext'
import { TokenSelectModal } from './TokenSelectModal/TokenSelectModal'

import { ChevronDownIcon } from '@/assets/icons/ChevronDown'

type TokenLabelProps = {
  contractAddress: TokenAddress | undefined
  iconUrl: string
}

export const TokenLabel: FC<TokenLabelProps> = ({
  contractAddress,
  iconUrl,
}) => {
  const { contractAddress: currentTokenAddress } = useParams()

  const { chainId } = useChainFromRoute()
  const { data: tokenData } = useTokenData(
    chainId as ChainId,
    contractAddress as `0x${string}`
  )

  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const { selectedToken, setSelectedTokenAddress } =
    useContext(SelectedTokenContext)
  const { addRecentToken } = useRecentTokens(chainId)

  if (contractAddress === currentTokenAddress) {
    return (
      <div className="h-8 flex bg-[rgba(255,255,255,0.08)] rounded-full justify-between items-center py-2 pl-2.5 pr-5 cursor-default">
        <img src={iconUrl} className="w-4 h-4 mr-2" />
        <span className="text-sm">{tokenData?.tokenSymbol}</span>
      </div>
    )
  }

  return (
    <>
      <div
        onClick={() => setIsOpenDialog(true)}
        className="h-8 flex bg-[rgba(255,255,255,0.08)] border border-secondary rounded-full justify-between items-center py-2 px-3 cursor-pointer"
      >
        <span className="flex items-center">
          {iconUrl && <img src={iconUrl} className="w-4 h-4 mr-2" />}
          <span
            className={cn(
              'text-sm text-nowrap',
              contractAddress ? 'mr-2' : 'mr-0'
            )}
          >
            {tokenData?.tokenSymbol ? tokenData?.tokenSymbol : 'Select token'}
          </span>
        </span>
        <ChevronDownIcon className="ml-1" />
      </div>
      <TokenSelectModal
        currentTokenAddress={currentTokenAddress as TokenAddress}
        value={
          (
            selectedToken as {
              [x: string]: `0x${string}` | undefined
            }
          ).address
        }
        onChange={(tokenAddress, { method }) => {
          setSelectedTokenAddress(tokenAddress)
          if (method === 'list') {
            addRecentToken(tokenAddress)
          }
          setIsOpenDialog(false)
        }}
        isOpen={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
      />
    </>
  )
}
