import { ReactNode } from 'react'
import reactNodeToString from 'react-node-to-string'

export function getAriaLabel(
  ariaLabel: undefined | string | ((isActive: boolean) => string),
  label: ReactNode,
  isActive: boolean,
  transformLabelStr?: (labelStr: string, isActive: boolean) => string
): string {
  if (typeof ariaLabel === 'string') {
    return ariaLabel
  } else if (typeof ariaLabel === 'function') {
    return ariaLabel(isActive)
  } else if (typeof ariaLabel === 'undefined') {
    const labelStr =
      typeof label === 'string' ? label : reactNodeToString(label)
    return transformLabelStr?.(labelStr, isActive) ?? labelStr
  }
  return ariaLabel
}
