import { FC, useEffect, useRef, useState } from 'react'
import { registerSW } from 'virtual:pwa-register'

const intervalMS = 60 * 1000

export const UpdateDetector: FC = () => {
  const updateSW = useRef<(reloadPage?: boolean | undefined) => Promise<void>>()
  const updatePwaInterval = useRef<NodeJS.Timeout>()
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false)

  useEffect(() => {
    updateSW.current = registerSW({
      onRegisteredSW(swUrl, r) {
        if (r) {
          updatePwaInterval.current = setInterval(async () => {
            if (!(!r.installing && navigator)) return

            if ('connection' in navigator && !navigator.onLine) return

            try {
              const resp = await fetch(swUrl, {
                cache: 'no-store',
                headers: {
                  cache: 'no-store',
                  'cache-control': 'no-cache',
                },
              })

              if (resp?.status === 200) await r.update()
            } catch (error) {} // eslint-disable-line no-empty
          }, intervalMS)
        }
      },
      onNeedRefresh() {
        setIsUpdateAvailable(true)
      },
    })
  }, [])

  if (!isUpdateAvailable) {
    return null
  }

  return (
    <>
      <div className="absolute h-safe-area-top top-0 w-full bg-purple-900 z-10"></div>
      <div
        onClick={() => updateSW.current?.()}
        className="cursor-pointer w-full text-xs text-white bg-purple-900 border-b border-purple-100 items-center justify-center relative z-10"
      >
        <div className="px-4 py-2 grow font-bold text-center">
          New version available! Click to refresh.
        </div>
      </div>
    </>
  )
}
