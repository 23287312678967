import { FC } from 'react'
import { useLocation } from 'react-router-dom'

import {
  CHAIN_DROPDOWN_ITEMS,
  ChainId,
} from '@/modules/tokenList/constants/chains'

import { useChainFromLocalStorage } from '../hooks/useChain'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './common/Select'

export const ChainSelect: FC = () => {
  const { pathname } = useLocation()
  const isHomePage = pathname === '/'

  const { chainId, setChainId } = useChainFromLocalStorage()

  return (
    <Select
      onValueChange={(v) => setChainId(Number(v) as ChainId)}
      value={chainId?.toString()}
      disabled={!isHomePage}
    >
      <SelectTrigger className="w-[12rem] items-center">
        <SelectValue placeholder="Select a chain" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {CHAIN_DROPDOWN_ITEMS.map((c) => (
            <SelectItem key={c.value} value={String(c.value)}>
              <div className="flex">
                <img src={c.url} className="w-4 h-4 mr-2" />
                <span>{c.label}</span>
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
