import { forwardRef, useEffect, useState } from 'react'

import { cn } from '@/core/utils/classNames'

import { Button } from './common/Button'

import { CloseCircleIcon } from '@/assets/CloseCircleIcon'
import { SearchIcon } from '@/assets/SearchIcon'

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  defaultValue?: string | number | readonly string[] | undefined
  onChange: (value: string) => void
}

const SearchInput = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, defaultValue, value: inputValue, onChange, ...props },
    ref
  ) => {
    const [value, setValue] = useState<
      string | number | readonly string[] | undefined
    >(defaultValue ?? '')
    useEffect(() => {
      if (typeof inputValue === 'undefined') {
        return
      }
      setValue(inputValue)
    }, [inputValue])

    return (
      <div className={cn('relative', className)}>
        <SearchIcon className="lucide lucide-search absolute left-3.5 top-3 h-5 w-5 text-muted-foreground" />
        <input
          type={type}
          className="flex bg-[#222730] h-11 w-full rounded-full border border-input border-cool-gray-700 border-opacity-10 px-10 pt-1 pb-1.5 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          ref={ref}
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
            onChange?.(e.target.value)
          }}
          {...props}
        />
        {value && (
          <Button
            size="icon"
            variant="iconGhost"
            className="absolute top-1 right-1"
            onClick={() => {
              setValue('')
              onChange?.('')
            }}
          >
            <CloseCircleIcon className="h-5 w-5" />
          </Button>
        )}
      </div>
    )
  }
)

SearchInput.displayName = 'SearchInput'

export { SearchInput }
