import { useGetOkxTokenData } from '@/modules/tokenDetails/hooks/useGetOkxTokenData'
import { ChainId } from '@/modules/tokenList/constants/chains'

import { OkxToken } from '../services/client/okx/types'
import { TokenAddress } from '../types/token'
import { makeLocalStorageHook } from './useStorage/makeStorageHook'

const MAX_RECENT_TOKENS = 6

export const useStoredRecentTokens = makeLocalStorageHook<
  Partial<Record<ChainId, TokenAddress>>
>(
  'APPVAR_recent-tokens',
  {},
  {
    serializer: (v) => JSON.stringify(v),
    deserializer: (v) => JSON.parse(v),
  }
)

type UseRecentTokens = {
  recentTokens: OkxToken[]
  addRecentToken: (contractAddress: TokenAddress) => void
  isLoading: boolean
}

export function useRecentTokens(chainId: ChainId | undefined): UseRecentTokens {
  const [storedRecentTokens, setStoredRecentTokens] = useStoredRecentTokens()

  const { getOkxTokenData, isLoading } = useGetOkxTokenData(chainId as ChainId)

  if (typeof chainId === 'undefined') {
    return {
      recentTokens: [],
      addRecentToken: () => undefined,
      isLoading: true,
    }
  }

  if (typeof chainId === 'undefined') {
    return {
      recentTokens: [],
      addRecentToken: () => undefined,
      isLoading: true,
    }
  }

  const recentTokens = isLoading
    ? []
    : (((storedRecentTokens[chainId] ?? []) as TokenAddress[])
        .map((contractAddress) => getOkxTokenData(contractAddress))
        .filter((token) => !!token) as OkxToken[])

  function addRecentToken(contractAddress: TokenAddress) {
    setStoredRecentTokens((storedRecentTokens) => {
      const recentTokenAddresses = (storedRecentTokens[chainId as ChainId] ??
        []) as TokenAddress[]
      if (recentTokenAddresses.includes(contractAddress)) {
        // Already recent, no need to change
        return storedRecentTokens
      }
      return {
        ...storedRecentTokens,
        [chainId as ChainId]: [contractAddress, ...recentTokenAddresses].slice(
          0,
          MAX_RECENT_TOKENS
        ),
      }
    })
  }

  return { recentTokens, addRecentToken, isLoading }
}
