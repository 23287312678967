import { useEffect, useRef } from 'react'
import {
  useVirtualizer,
  useWindowVirtualizer,
  VirtualItem,
  Virtualizer,
} from '@tanstack/react-virtual'

export interface FooterItem {
  start: number
  end: number
  size: number
}

export interface Fetcher<T> {
  items: T[]
  hasNextPage: boolean
  fetchNextPage: () => void
  isFetching: boolean
}

type WindowVirtualizerOption<TItemElement extends Element> = Parameters<
  typeof useWindowVirtualizer<TItemElement>
>[0]
type ElementVirtualizerOption<
  TScrollElement extends Element,
  TItemElement extends Element,
> = Parameters<typeof useVirtualizer<TScrollElement, TItemElement>>[0]
export type VirtualizerOptions<
  TScrollElement extends Element | Window,
  TItemElement extends Element,
> = TScrollElement extends Window
  ? WindowVirtualizerOption<TItemElement>
  : ElementVirtualizerOption<Exclude<TScrollElement, Window>, TItemElement>

export type VirtualItemWithData<T> = VirtualItem & {
  data: T
}

export type InfiniteVirtualizer<T> = {
  items: VirtualItemWithData<T>[]
  isLoading: boolean
  isEnd: boolean
  containerSize: number
  emptyFooter: FooterItem | undefined
  scrollForMoreFooter: FooterItem | undefined
  loadingFooter: FooterItem | undefined
  endedFooter: FooterItem | undefined
}

export type FooterElement = { size: number; gap?: number }
export type FooterElements = Partial<Record<FooterType, FooterElement>>

type FooterType = 'empty' | 'scrollForMore' | 'loading' | 'ended'

export type InfiniteVisualizerOptions<
  T,
  TScrollElement extends Element | Window,
  TItemElement extends Element,
> = {
  fetcher: Fetcher<T>
  virtualizerOptions: Omit<
    VirtualizerOptions<TScrollElement, TItemElement>,
    'count'
  >
  footerElements?: FooterElements
}

function createInfiniteVirtualizer<
  T,
  TScrollElement extends Element | Window,
  TItemElement extends Element,
>(
  type: 'window' | 'element'
): (
  options: InfiniteVisualizerOptions<T, TScrollElement, TItemElement>
) => InfiniteVirtualizer<T> {
  const useVariableVirtualizer = (
    type === 'window' ? useWindowVirtualizer : useVirtualizer
  ) as TScrollElement extends Window
    ? typeof useWindowVirtualizer
    : typeof useVirtualizer

  function useInfiniteVirtualizer<
    T,
    TScrollElement extends Element | Window,
    TItemElement extends Element,
  >(
    options: InfiniteVisualizerOptions<T, TScrollElement, TItemElement>
  ): InfiniteVirtualizer<T> {
    const { fetcher, virtualizerOptions, footerElements } = options
    const { items, hasNextPage, fetchNextPage, isFetching } = fetcher

    const virtualizer: Virtualizer<TScrollElement, TItemElement> =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (useVariableVirtualizer as any)({
        ...virtualizerOptions,
        count: items.length,
      })

    const virtualItems = virtualizer.getVirtualItems()
    const isAlreadyFetching = useRef(false)
    useEffect(() => {
      if (virtualItems.length === 0) {
        return
      }

      const lastItem = virtualItems.at(-1) as VirtualItem

      if (
        lastItem.index >= items.length - 1 &&
        hasNextPage &&
        !isFetching &&
        !isAlreadyFetching.current
      ) {
        isAlreadyFetching.current = true
        fetchNextPage()
      }
    }, [hasNextPage, fetchNextPage, isFetching, items.length, virtualItems])
    useEffect(() => {
      // Prevent duplicate re-fetches
      if (!isFetching) {
        requestAnimationFrame(() => {
          isAlreadyFetching.current = false
        })
      }
    }, [isFetching])

    const virtualItemsWithData = virtualItems.map((virtualItem) => ({
      ...virtualItem,
      data: items[virtualItem.index],
    }))

    const defaultGap = virtualizerOptions.gap ?? 0
    const isLoading = isFetching
    const isEnded = !hasNextPage

    const footerToShow = ((): FooterType => {
      if (isLoading) {
        return 'loading'
      } else if (!isEnded) {
        return 'scrollForMore'
      } else if (items.length === 0) {
        return 'empty'
      } else {
        return 'ended'
      }
    })()

    const containerSize = computeContainerSize({
      baseContainerSize: virtualizer.getTotalSize(),
      footerToShow,
      defaultGap,
      footerElements,
    })

    const bottomY = virtualItems.at(-1)?.end ?? 0

    const emptyFooter = computeFooterElement({
      bottomY,
      footerElem: footerElements?.empty,
      defaultGap,
      show: footerToShow === 'empty',
    })
    const scrollForMoreFooter = computeFooterElement({
      bottomY,
      footerElem: footerElements?.scrollForMore,
      defaultGap,
      show: footerToShow === 'scrollForMore',
    })
    const loadingFooter = computeFooterElement({
      bottomY,
      footerElem: footerElements?.loading,
      defaultGap,
      show: footerToShow === 'loading',
    })
    const endedFooter = computeFooterElement({
      bottomY,
      footerElem: footerElements?.ended,
      defaultGap,
      show: footerToShow === 'ended',
    })

    return {
      items: virtualItemsWithData,
      isLoading,
      isEnd: !hasNextPage,
      containerSize,
      emptyFooter,
      scrollForMoreFooter,
      loadingFooter,
      endedFooter,
    }
  }

  return useInfiniteVirtualizer
}

export const useInfiniteWindowVirtualizer = createInfiniteVirtualizer(
  'window'
) as <T, TScrollElement extends Window, TItemElement extends Element>(
  options: InfiniteVisualizerOptions<T, TScrollElement, TItemElement>
) => InfiniteVirtualizer<T>
export const useInfiniteVirtualizer = createInfiniteVirtualizer('element') as <
  T,
  TScrollElement extends Element,
  TItemElement extends Element,
>(
  options: InfiniteVisualizerOptions<T, TScrollElement, TItemElement>
) => InfiniteVirtualizer<T>

function computeContainerSize(options: {
  baseContainerSize: number
  footerToShow: FooterType
  defaultGap: number
  footerElements: undefined | FooterElements
}): number {
  const { baseContainerSize, footerToShow, defaultGap, footerElements } =
    options

  let containerSize = baseContainerSize

  if (footerToShow === 'loading' && footerElements?.loading) {
    containerSize += footerElements.loading.size
    containerSize += footerElements.loading.gap ?? defaultGap
  }

  if (footerToShow === 'empty' && footerElements?.empty) {
    containerSize += footerElements.empty.size
    containerSize += footerElements.empty.gap ?? defaultGap
  }

  if (footerToShow === 'ended' && footerElements?.ended) {
    containerSize += footerElements.ended.size
    containerSize += footerElements.ended.gap ?? defaultGap
  }

  if (footerToShow === 'scrollForMore' && footerElements?.scrollForMore) {
    containerSize += footerElements.scrollForMore.size
    containerSize += footerElements.scrollForMore.gap ?? defaultGap
  }

  return containerSize
}

function computeFooterElement(options: {
  bottomY: number
  footerElem: FooterElement | undefined
  defaultGap: number
  show: boolean
}): FooterItem | undefined {
  const { bottomY, footerElem, defaultGap, show } = options
  if (!show || !footerElem) {
    return undefined
  }
  const size = footerElem.size
  const start = bottomY + (bottomY > 0 ? footerElem.gap ?? defaultGap : 0)
  const end = start + size
  return { start, end, size }
}
