import { ChainId } from '@/modules/tokenList/constants/chains'

import API from '../../api'

export type FetchAllZarklabTokensOptions = {
  page: number
  limit: number
  chainId: ChainId
  search: string
  isHotToken?: boolean
  isTopGainer?: boolean
  isNew?: boolean
}
export type FetchAllZarklabTokensResponse = {
  data: Array<{
    chain: string
    logoUrl: string
    symbol: string
    name: string
    decimals: string
    address: `0x${string}`
    marketCap: number
    zarkAIScore: number
    volume24h: number
    volume1h: number
    price: {
      change1h: number
      change24h: number
      current: number
      hourlyHistory: number[] // array of prices
    }
    volume: { past24h: number }
  }>
  pagination?: {
    page: number
    totalPages: number
    limit: number
  }
}
export async function fetchAllZarklabTokens(
  options: FetchAllZarklabTokensOptions
): Promise<FetchAllZarklabTokensResponse> {
  const { page, limit, chainId, search, isHotToken, isTopGainer, isNew } =
    options
  return await API.get(`/dex/tokens`, {
    page: String(page),
    limit: String(limit),
    chainId: String(chainId),
    isHotToken: isHotToken ? 'true' : 'false',
    isTopGainer: isTopGainer ? 'true' : 'false',
    isNew: isNew ? 'true' : 'false',
    search,
  })
}
