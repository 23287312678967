import { useCallback } from 'react'
import copy from 'copy-to-clipboard'

import { useToast } from './useToast'

export const useCopy = () => {
  const { toast } = useToast()

  const handleCopy = useCallback(
    (text: string) => {
      copy(text)
      toast({
        description: <span className="font-semibold">Copied to clipboard</span>,
      })
    },
    [toast]
  )

  return {
    handleCopy,
  }
}
