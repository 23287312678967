import { OkxToken } from '@/core/services/client/okx/types'
import { TokenAddress } from '@/core/types/token'
import { useOkxTokens, UseOkxTokensReturnType } from '@/core/hooks/useOkxTokens'

import { ChainId } from '@/modules/tokenList/constants/chains'

type BaseReturnType = Omit<UseOkxTokensReturnType, 'data'>

export type UseGetOkxTokenDataReturnType =
  | (BaseReturnType & {
      isLoading: true
      getOkxTokenData: (contractAddress: TokenAddress) => undefined
    })
  | (BaseReturnType & {
      isLoading: false
      getOkxTokenData: (contractAddress: TokenAddress) => OkxToken | undefined
    })

export function useGetOkxTokenData(
  chainId: ChainId
): UseGetOkxTokenDataReturnType {
  const { data: okxTokens, ...otherQueryProps } = useOkxTokens(chainId)

  function getOkxTokenData(
    contractAddress: TokenAddress
  ): OkxToken | undefined {
    if (otherQueryProps.isLoading || !okxTokens) {
      return undefined
    }
    return okxTokens.find(
      (token) => token.tokenContractAddress === contractAddress
    )
  }

  return { getOkxTokenData, ...otherQueryProps } as UseGetOkxTokenDataReturnType
}
