import { FC, useEffect, useRef } from 'react'
import { createChart, Time } from 'lightweight-charts'

import { ZarklabTokenHistory } from '@/core/types/token'

type ChartProps = {
  className?: string
  activities: ZarklabTokenHistory
}

export const Chart: FC<ChartProps> = ({ className = '', activities }) => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const chart = createChart(chartContainerRef.current || '', {
      autoSize: true,
      layout: {
        background: { color: 'rgba(112, 0, 255, 0)' },
        textColor: '#DDD',
        attributionLogo: false,
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
      timeScale: {
        visible: false,
      },
      rightPriceScale: {
        visible: false,
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false,
        },
        vertLine: {
          visible: false,
          labelVisible: false,
        },
      },
      handleScale: false,
      handleScroll: false,
    })

    const areaSeries = chart.addAreaSeries({
      lineColor: '#8162D0',
      lineWidth: 1,
      topColor: '#8162D066',
      bottomColor: 'rgba(129, 98, 208, 0)',
      priceLineVisible: false,
      crosshairMarkerVisible: false,
      lastValueVisible: false,
    })

    const data = activities
      .map((item) => {
        const date = new Date(item.time)
        const time = date.getTime() / 1000

        return { time, value: item.price }
      })
      .sort((a, b) => a.time - b.time)
      .filter((item, index, self) => {
        return index === 0 || item.time !== self[index - 1].time
      }) as unknown as {
      time: Time
      value: number
    }[]

    areaSeries.setData(data)

    chart.timeScale().fitContent()

    return () => chart.remove()
  }, [activities])

  return <div className={className} ref={chartContainerRef} />
}
