import { SelectedTokenProvider } from './SelectedTokenContext'
import { SwapForm } from './SwapForm'

export const SwapFormWithProvider = () => {
  return (
    <SelectedTokenProvider>
      <SwapForm />
    </SelectedTokenProvider>
  )
}
