import { FC } from 'react'

type LossArrowIconProps = {
  className?: string
}

export const LossArrowIcon: FC<LossArrowIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8C14 7.632 13.702 7.33333 13.3333 7.33333C12.9647 7.33333 12.6667 7.632 12.6667 8V9.53133L9.83934 6.23267C9.62867 5.986 9.27001 5.92733 8.99001 6.09533L6.14667 7.80133L3.17934 4.24C2.94334 3.958 2.52401 3.918 2.23934 4.15467C1.95734 4.39 1.91867 4.81067 2.15401 5.09333L5.48734 9.09333C5.69867 9.346 6.06201 9.408 6.34334 9.238L9.19334 7.528L11.884 10.6667H10C9.63134 10.6667 9.33334 10.9653 9.33334 11.3333C9.33334 11.7013 9.63134 12 10 12H13.3333C13.416 12 13.498 11.982 13.576 11.9507C13.6087 11.938 13.634 11.9167 13.664 11.9C13.6987 11.8793 13.736 11.8667 13.7667 11.8393C13.7733 11.834 13.776 11.826 13.782 11.82C13.8187 11.7867 13.844 11.746 13.872 11.7053C13.894 11.6733 13.92 11.6453 13.9353 11.6107C13.952 11.5753 13.9573 11.536 13.9667 11.498C13.9793 11.4513 13.9933 11.406 13.9947 11.358C13.9953 11.3493 14 11.342 14 11.3333V8Z"
        fill="#EE6A1D"
      />
    </svg>
  )
}
