import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

import { MainLayout } from './core/layouts/MainLayout/MainLayout'
import { Error } from './core/pages/Error'

import { TokenDetails } from './modules/tokenDetails/pages/TokenDetails'
import TokenListPage from './modules/tokenList/pages/TokenListPage'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />} errorElement={<Error />}>
      <Route index element={<TokenListPage />} />
      <Route
        element={<TokenDetails />}
        path="/token-details/:chain/:contractAddress"
      />
    </Route>
  )
)
