import { TokenAddress } from '@/core/types/token'

import { ChainId } from '@/modules/tokenList/constants/chains'

export const MAJOR_TOKENS = {
  1: [
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', // ETH
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
    '0x50327c6c5a14dcade707abad2e27eb517df87ab5', // TRON
  ],
} as const satisfies Partial<Record<ChainId, TokenAddress[]>>
