import { forwardRef, ReactNode, useEffect, useState } from 'react'

import { getAriaLabel } from '@/core/utils/aria'
import { cn } from '@/core/utils/classNames'

interface TabInfo {
  id: string
  label: ReactNode
  'aria-label'?: string
}

export interface ButtonToggleProps {
  tabs: TabInfo[]
  value?: string
  defaultValue?: string
  onChange?: (value: string) => void
  className?: string
}

const Tabs = forwardRef<HTMLDivElement, ButtonToggleProps>((props, ref) => {
  const { tabs, value: inputValue, defaultValue, onChange, className } = props

  const [value, _setValue] = useState(defaultValue ?? inputValue)
  function setValue(v: string) {
    _setValue(v)
    onChange?.(v)
  }

  useEffect(() => {
    _setValue(inputValue)
  }, [inputValue])

  return (
    <div ref={ref} className={cn('overflow-x-auto', className)}>
      <div className="flex gap-4">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={cn(
              'relative h-10 px-8 py-2 hover:bg-secondary/40 transition-[background-color] rounded-t-md flex-shrink-0',
              "after:content-[''] after:bg-secondary-400 after:absolute after:left-0 after:right-0 after:bottom-0 after:h-0.5 after:transition-[opacity]",
              value === tab.id ? 'after:opacity-100' : 'after:opacity-40'
            )}
            aria-label={getAriaLabel(
              tab['aria-label'],
              tab.label,
              value === tab.id,
              (label, isActive) => (isActive ? label : `Show ${label}`)
            )}
            disabled={value === tab.id}
            onClick={() => setValue(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  )
})
Tabs.displayName = 'Tabs'

export default Tabs
