import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import {
  binanceWallet,
  bybitWallet,
  coinbaseWallet,
  krakenWallet,
  ledgerWallet,
  metaMaskWallet,
  okxWallet,
  trustWallet,
  uniswapWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { arbitrum, mainnet, optimism, polygon } from '@wagmi/core/chains'
import { compress, decompress } from 'lz-string'
import { webSocket, WebSocketTransport } from 'viem'
import { createConfig, deserialize, serialize } from 'wagmi'
import { hashFn } from 'wagmi/query'

import { TokenAddress } from './core/types/token'

export interface IApplicationConfig {
  stage: string
  projectId: string
  currency: string
  apiBaseUrl: string
  ethereumTokenAddress: TokenAddress
  arbitrumTokenAddress: TokenAddress
  ethereumWebSocketTransportUrl: string
  arbitrumWebSocketTransportUrl: string
  polygonWebSocketTransportUrl: string
  optimismWebSocketTransportUrl: string
  okxAccessKey: string
  okxAccessPassphrase: string
  okxSecretKey: string
  okxApiBaseUrl: string
  zarklabApiBaseUrl: string
  region: string | undefined
  userPoolId: string | undefined
  userPoolWebClientId: string | undefined
  identityPoolId: string | undefined
}

const appConfig: IApplicationConfig = {
  stage: process.env.REACT_APP_STAGE || '',
  projectId: '41734ed452dc626491cdd5af7dfa8bb9',
  currency: 'RPK',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
  ethereumTokenAddress:
    (process.env.REACT_APP_ETHEREUM_TOKEN_ADDRESS as TokenAddress) || '',
  arbitrumTokenAddress:
    (process.env.REACT_APP_ARBITRUM_TOKEN_ADDRESS as TokenAddress) || '',
  ethereumWebSocketTransportUrl:
    process.env.REACT_APP_ETHEREUM_WEBSOCKET_TRANSPORT_URL || '',
  arbitrumWebSocketTransportUrl:
    process.env.REACT_APP_ARBITRUM_WEBSOCKET_TRANSPORT_URL || '',
  polygonWebSocketTransportUrl:
    process.env.REACT_APP_POLYGON_WEBSOCKET_TRANSPORT_URL || '',
  optimismWebSocketTransportUrl:
    process.env.REACT_APP_OPTIMISM_WEBSOCKET_TRANSPORT_URL || '',
  okxAccessKey: process.env.REACT_APP_OKX_ACCESS_KEY || '',
  okxAccessPassphrase: process.env.REACT_APP_OKX_ACCESS_PASSPHRASE || '',
  okxSecretKey: process.env.REACT_APP_OKX_SECRET_KEY || '',
  okxApiBaseUrl: process.env.REACT_APP_OKX_API_BASE_URL || '',
  zarklabApiBaseUrl: process.env.REACT_APP_ZARKLAB_API_BASE_URL || '',
  region: process.env.REACT_APP_PUBLIC_AWS_REGION,
  userPoolId: process.env.REACT_APP_PUBLIC_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_PUBLIC_AWS_USER_POOL_CLIENT_ID,
  identityPoolId: process.env.REACT_APP_PUBLIC_AWS_IDENTITY_POOL_ID,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      networkMode: 'offlineFirst',
      queryKeyHashFn: hashFn,
    },
  },
})

const persister = createSyncStoragePersister({
  key: 'dex-cache',
  serialize: (data) => compress(serialize(data)),
  storage: window.localStorage,
  deserialize: (data) => deserialize(decompress(data)),
})

const transports: Record<number, WebSocketTransport> = {
  [mainnet.id]: webSocket(appConfig.ethereumWebSocketTransportUrl, {
    keepAlive: { interval: 45_000 },
  }),
  [arbitrum.id]: webSocket(appConfig.arbitrumWebSocketTransportUrl, {
    keepAlive: { interval: 45_000 },
  }),
  [polygon.id]: webSocket(appConfig.polygonWebSocketTransportUrl, {
    keepAlive: { interval: 45_000 },
  }),
  [optimism.id]: webSocket(appConfig.optimismWebSocketTransportUrl, {
    keepAlive: { interval: 45_000 },
  }),
}

const wagmiProviderConfig = getDefaultConfig({
  appName: 'RepubliK AI DEX',
  projectId: appConfig.projectId,
  chains: [mainnet, arbitrum, polygon, optimism],
  transports,
  wallets: [
    {
      groupName: 'Wallets',
      wallets: [
        metaMaskWallet,
        okxWallet,
        trustWallet,
        binanceWallet,
        bybitWallet,
        coinbaseWallet,
        uniswapWallet,
        krakenWallet,
        ledgerWallet,
      ],
    },
  ],
})

const rainbowKitTheme = {
  accentColor: '#CCFF00',
  accentColorForeground: 'black',
  backgroundColor: '#131820',
  foregroundColor: 'white',
  textColor: 'white',
  fonts: {
    body: 'Roboto, Inter, sans-serif',
    heading: 'Roboto, Inter, sans-serif',
  },
}

const transportConfig = createConfig({
  chains: [mainnet, arbitrum, polygon, optimism],
  transports,
})

export {
  queryClient,
  wagmiProviderConfig,
  rainbowKitTheme,
  appConfig,
  persister,
  transportConfig,
}
