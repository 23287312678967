import { createSearchParams } from 'react-router-dom'
import cryptoJS from 'crypto-js'

import API from '../../api'

import { appConfig } from '@/config'

const getOkxHeaders = (url: string) => {
  // Get the current time
  const date = new Date()

  const path = new URL(url).pathname + new URL(url).search

  return {
    'Content-Type': 'application/json',
    // The api Key obtained from the previous application
    'OK-ACCESS-KEY': appConfig.okxAccessKey,
    'OK-ACCESS-SIGN': cryptoJS.enc.Base64.stringify(
      // The field order of headersParams should be consistent with the order of quoteParams.
      // example : quote  ==>   cryptoJS.HmacSHA256(timestamp + 'GET' + '/api/v5/dex/aggregator/quote?amount=1000000&chainId=1&toTokenAddress=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&fromTokenAddress=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', secretKey)
      cryptoJS.HmacSHA256(
        date.toISOString() + 'GET' + path, // TODO [JIRA:R2-33]: Remove OKX headers once the APIs have been proxied by the backend
        appConfig.okxSecretKey
      )
    ),
    // Convert the current time to the desired format
    'OK-ACCESS-TIMESTAMP': date.toISOString(),
    // The password created when applying for the key
    'OK-ACCESS-PASSPHRASE': appConfig.okxAccessPassphrase,
  }
}

const OkxAPI = {
  ...API,
  get: async (
    endpoint: string,
    searchParams?: URLSearchParams | Record<string, string>
  ) => {
    const params = searchParams
      ? '?' + createSearchParams(searchParams).toString()
      : ''
    const headers = getOkxHeaders(
      `${appConfig.okxApiBaseUrl}${endpoint}${params}`
    )
    return await API.get(
      `${appConfig.okxApiBaseUrl}${endpoint}`,
      searchParams,
      headers
    )
  },
}

export default OkxAPI
