import { FC } from 'react'

type InfoIconProps = {
  className?: string
}

export const InfoIcon: FC<InfoIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 6C7.63204 6 7.33337 5.70134 7.33337 5.33334C7.33337 4.96534 7.63204 4.66667 8.00004 4.66667C8.36804 4.66667 8.66671 4.96534 8.66671 5.33334C8.66671 5.70134 8.36804 6 8.00004 6ZM8.66671 10.6667C8.66671 11.0347 8.36804 11.3333 8.00004 11.3333C7.63204 11.3333 7.33337 11.0347 7.33337 10.6667V7.33334C7.33337 6.96534 7.63204 6.66667 8.00004 6.66667C8.36804 6.66667 8.66671 6.96534 8.66671 7.33334V10.6667ZM8.00004 1.33334C4.31804 1.33334 1.33337 4.318 1.33337 8C1.33337 11.682 4.31804 14.6667 8.00004 14.6667C11.6814 14.6667 14.6667 11.682 14.6667 8C14.6667 4.318 11.6814 1.33334 8.00004 1.33334Z"
        fill="white"
        fillOpacity="0.6"
      />
    </svg>
  )
}
