import truncateEthAddress from 'truncate-eth-address'

import { Button } from '@/core/components/common/Button'
import { Card } from '@/core/components/common/Card'
import { useCopy } from '@/core/hooks/useCopy'

import { useTokenDetails } from '../../hooks/useTokenDetails'

import { Copy } from '@/assets/Copy'

export const TokenInfo = () => {
  const { handleCopy } = useCopy()

  const { tokenDetails } = useTokenDetails()
  const { tokenAddress, officialWebsite, socialUrls } = tokenDetails ?? {}

  return (
    <Card className="w-full h-full relative">
      <div className="py-2">
        <span className="flex font-medium">Info</span>
        <div className="flex md:max-lg:flex-col md:max-lg:items-start items-center justify-between gap-2 my-2">
          <span className="text-sm text-gray-350">Contract address:</span>
          <Button
            onClick={() => handleCopy(tokenAddress ?? '')}
            className="h-8 md:h-9 text-xxs md:text-sm"
            variant="outline"
          >
            <span className="mr-2">
              {truncateEthAddress(tokenAddress ?? '')}
            </span>
            <Copy />
          </Button>
        </div>
        {/* <span className="text-sm text-gray-350 line-clamp-3 pt-2.5 pb-2">
          {tokenInfo.tokenDetails}
        </span> */}
        <div className="grid gap-2">
          <TokenLink
            name="Official Site"
            links={[officialWebsite] as string[]}
          />
          <TokenLink name="Whitepaper" links={socialUrls?.whitepaper} />
          <TokenLink name="Twitter" links={socialUrls?.twitter} />
          <TokenLink name="Telegram" links={socialUrls?.telegram} />
          <TokenLink name="Github" links={socialUrls?.github} />
        </div>
      </div>
    </Card>
  )
}

const TokenLink = ({
  name,
  links,
}: {
  name: string
  links: string[] | undefined
}) => {
  return (
    links && (
      <div className="flex gap-1 rounded-2xl bg-gray-850 py-3 px-3 overflow-hidden">
        <span className="text-gray-550 min-w-24">{name}:</span>
        <div className="flex flex-col break-all gap-2">
          {links.map((link, index) => (
            <a
              key={index}
              href={link}
              target="_blank"
              rel="noreferrer"
              className="underline hover:text-blue-500 transition-colors break-words"
              aria-label={`Visit ${name}`}
            >
              {link}
            </a>
          ))}
        </div>
      </div>
    )
  )
}
