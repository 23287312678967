import { FC, SVGProps } from 'react'

export const Copy: FC<SVGProps<SVGSVGElement>> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 10.8333V10C7.5 8.62167 8.62167 7.5 10 7.5H10.8333V4.7225C10.8333 4.41583 10.5842 4.16667 10.2775 4.16667H4.7225C4.41583 4.16667 4.16667 4.41583 4.16667 4.7225V10.2775C4.16667 10.5842 4.41583 10.8333 4.7225 10.8333H7.5ZM7.5 12.5H4.7225C3.49667 12.5 2.5 11.5033 2.5 10.2775V4.7225C2.5 3.49667 3.49667 2.5 4.7225 2.5H10.2775C11.5033 2.5 12.5 3.49667 12.5 4.7225V7.5H15C16.3783 7.5 17.5 8.62167 17.5 10V15C17.5 16.3783 16.3783 17.5 15 17.5H10C8.62167 17.5 7.5 16.3783 7.5 15V12.5ZM9.16667 10C9.16667 9.54083 9.54083 9.16667 10 9.16667H15C15.46 9.16667 15.8333 9.54083 15.8333 10V15C15.8333 15.4592 15.46 15.8333 15 15.8333H10C9.54083 15.8333 9.16667 15.4592 9.16667 15V10Z"
        fill="white"
      />
    </svg>
  )
}
