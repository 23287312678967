import { Auth } from 'aws-amplify'
import { useAccount, useSignMessage } from 'wagmi'

import { TokenAddress } from '../types/token'

import { authenticateUser, handleSignIn, ICognitoUser } from '@/services/auth'

const useAuth = () => {
  const { address } = useAccount()
  const { signMessageAsync } = useSignMessage()

  const signIn = async () => {
    const cognitoUser = await handleSignIn({
      address: address as TokenAddress, // TODO [JIRA:R2-32]: Investigate if an undefined address will cause sign in problems
    })

    // We get the message to sign
    const message = cognitoUser?.challengeParam?.message

    // Request user to Sign the message using his crypto wallet and private key
    const signature = await signMessageAsync({ message })

    // We send the signature back to Cognito to complete the authentication process.
    await Auth.sendCustomChallengeAnswer(cognitoUser, signature).catch(
      (err) => {
        console.error('Amplify Send custom challenge answer err', err)
        throw err
      }
    )

    const user = await authenticateUser()

    if (!user) {
      console.error('Amplify authentication Failed')
      throw 'Authentication Failed'
    } else {
      console.log('Amplify authentication Success', user)
    }
  }

  const getUserInfo = async (): Promise<ICognitoUser | null> => {
    return await authenticateUser()
  }

  return {
    signIn,
    getUserInfo,
  }
}

export { useAuth }
