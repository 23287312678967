import API from '../../api'

// [2024-11-11] Currently a backend proxy of the OKX endpoint at
// https://www.okx.com/web3/build/docs/waas/dex-swap

export type FetchSwapOptions = {
  chainId: string
  amount: string
  fromTokenAddress: `0x${string}`
  toTokenAddress: `0x${string}`
  slippage: number
  userWalletAddress: `0x${string}`
  referrerAddress?: string
  swapReceiverAddress?: `0x${string}`
  feePercent?: string
  gasLimit?: string
  gasLevel?: string
  dexIds?: string
  priceImpactProtectionPercentage?: string
  callDataMemo?: string
  toTokenReferrerAddress?: string
  computeUnitPrice?: string
  computeUnitLimit?: string
  fromTokenReferrerWalletAddress?: `0x${string}`
  toTokenReferrerWalletAddress?: `0x${string}`
}

interface FetchSwapResponseToken {
  decimal: string
  tokenContractAddress: string
  tokenSymbol: string
  tokenUnitPrice: string
}

export interface FetchSwapResponse {
  code: string
  data: Array<{
    routerResult: {
      chainId: string
      dexRouterList: Array<{
        router: string
        routerPercent: string
        subRouterList: Array<{
          dexProtocol: Array<{
            dexName: string
            percent: string
          }>
          fromToken: FetchSwapResponseToken
          toToken: FetchSwapResponseToken
        }>
      }>
      estimateGasFee: string
      fromToken: FetchSwapResponseToken
      fromTokenAmount: string
      quoteCompareList: Array<{
        amountOut: string
        dexLogo: string
        dexName: string
        tradeFee: string
      }>
      toToken: FetchSwapResponseToken
      toTokenAmount: string
    }
    tx: {
      data: `0x${string}` // this is not a token address!
      from: `0x${string}`
      gas: string
      gasPrice: string
      maxPriorityFeePerGas: string
      minReceiveAmount: string
      to: `0x${string}`
      value: string
      signatureData?: Array<unknown> // As of 2024-11-11, the type is not clearly defined on https://www.okx.com/web3/build/docs/waas/dex-swap
    }
  }>
  msg: string
}

export async function fetchSwap(
  options: FetchSwapOptions
): Promise<FetchSwapResponse['data'][number]['tx']> {
  const {
    chainId,
    fromTokenAddress,
    toTokenAddress,
    amount,
    slippage,
    userWalletAddress,
  } = options
  const response = (await API.get('/dex/swap', {
    chainId,
    fromTokenAddress,
    toTokenAddress,
    amount,
    slippage: String(slippage),
    userWalletAddress,
  })) as FetchSwapResponse
  return response?.data[0]?.tx
}
