import { CSSProperties, FC } from 'react'
import { cx } from 'class-variance-authority'

import { ZarklabToken } from '@/core/types/token'
import { ButtonLink } from '@/core/components/common/ButtonLink'
import { PriceChangeBadge } from '@/core/components/PriceChangeBadge'
import { cn } from '@/core/utils'
import { getTokenDetailsPath } from '@/core/utils/getTokenDetailsPath'
import { compactNumbers } from '@/core/utils/utils'

import { Chart } from './Chart'

import { ClockIcon } from '@/assets/icons/ClockIcon'
import { GrowthArrowIcon } from '@/assets/icons/GrowthArrow'
import { LossArrowIcon } from '@/assets/icons/LossArrow'

export type TokenListRowProps = {
  token: ZarklabToken
  className?: string
  style?: CSSProperties
}

export const TokenListRow: FC<TokenListRowProps> = (props) => {
  const { token, className, style } = props

  if (!token) return null

  const {
    logoUrl,
    address,
    symbol,
    name,
    zarkAIScore,
    marketCap,
    volume,
    price,
    chain,
  } = token

  const { change24h, current, hourlyHistory } = price

  const detailsPath = getTokenDetailsPath(chain, address)

  return (
    <>
      {/* Desktop */}
      <div
        className={cn(
          'hidden md:flex justify-between items-center rounded-2xl bg-[#ffffff0a] px-3',
          className
        )}
        style={style}
      >
        <div className="w-48 flex items-center">
          <img className="w-8 h-8 mr-3" src={logoUrl} />
          <div className="flex flex-col">
            <span className="text-sm">{symbol}</span>
            <span className="text-xs text-[#abacaf]">{name}</span>
          </div>
        </div>
        <div className="flex flex-1 justify-evenly items-center">
          <div className="w-28 text-sm mr-2">{zarkAIScore}%</div>
          <div className="w-24 text-sm">{compactNumbers(marketCap)} USD</div>
          <div className="w-24 text-sm">
            {compactNumbers(volume?.past24h)} USD
          </div>
          <div className="w-32 text-sm flex flex-col">
            <span>{current} USD</span>
            <div className="flex items-center mt-1">
              {change24h > 0 && <GrowthArrowIcon className="mr-1" />}
              {change24h < 0 && <LossArrowIcon className="mr-1" />}
              {change24h === 0 && <div className="w-6" />}
              <span
                className={cx('text-xs mr-3', {
                  'text-growth': change24h > 0,
                  'text-loss': change24h < 0,
                })}
              >
                {change24h > 0 && '+'}
                {change24h}%
              </span>
              <ClockIcon className="w-4 text-[#abacaf]" />
              <span className="text-xs text-[#abacaf]">24h</span>
            </div>
          </div>
        </div>
        <div className="w-40 lg:w-64 h-full flex justify-end items-center -ml-4">
          <Chart
            className="w-24 h-full mr-6 hidden lg:block"
            activities={hourlyHistory}
          />
          <ButtonLink
            href={detailsPath}
            className="w-28 text-xs uppercase"
            variant="outline"
          >
            More
          </ButtonLink>
        </div>
      </div>
      {/* Mobile */}
      <div
        className={cn(
          'items-center justify-between px-4 py-2 bg-[#ffffff0a] flex md:hidden',
          className
        )}
        style={style}
      >
        <div className="min-w-36 h-full flex flex-col pr-4">
          <div className="h-full flex items-center">
            <img className="w-6 h-6 mr-2" src={logoUrl} />
            <div className="flex flex-col">
              <span className="text-xs">{symbol}</span>
              <span className="text-xs text-[#abacaf]">{name}</span>
            </div>
          </div>
          <Chart className="w-full h-full" activities={hourlyHistory} />
        </div>
        <div className="min-w-24 h-full flex flex-col justify-between">
          <span className="text-xs">{current} USD</span>
          <div className="flex flex-col">
            <PriceChangeBadge priceChange24h={change24h} />
          </div>
        </div>
        <div className="w-24 h-full flex flex-col justify-between">
          <span className="text-xs">{zarkAIScore}%</span>
          <ButtonLink
            href={detailsPath}
            className="text-xs uppercase"
            variant="outline"
          >
            More
          </ButtonLink>
        </div>
      </div>
    </>
  )
}
