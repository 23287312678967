import {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { TokenAddress } from '@/core/types/token'
import SpinnerBlock from '@/core/components/SpinnerBlock'
import { useChainFromLocalStorage } from '@/core/hooks/useChain'
import {
  Fetcher,
  useInfiniteVirtualizer,
} from '@/core/hooks/useInfiniteVirtualizer'
import { rem } from '@/core/utils/utils'

import { useGetOkxTokenData } from '@/modules/tokenDetails/hooks/useGetOkxTokenData'
import { ChainId } from '@/modules/tokenList/constants/chains'

import { MajorTokensSelect } from './MajorTokensSelect'
import { RecentTokensSelect } from './RecentTokensSelect'
import { ITEM_HEIGHT, TokenListItem } from './TokenListItem'

import { StarCircleIcon } from '@/assets/icons/StarCircleIcon'

type Token = {
  name: string
  symbol: string
  logoUrl: string
  address: TokenAddress
}

export type TokenListProps = {
  tokenFetcher: Fetcher<Token>
  showMajorTokens?: boolean
  showRecentSearches?: boolean

  value?: TokenAddress | undefined
  onChange?: (
    contractAddress: TokenAddress,
    options: { method: 'major-tokens' | 'recent-searches' | 'list' }
  ) => void
}

export const TokenList: FC<TokenListProps> = (props) => {
  const {
    tokenFetcher,
    showMajorTokens = true,
    showRecentSearches = true,
    value,
    onChange,
  } = props
  const containerRef = useRef<HTMLDivElement>(null)

  const {
    Elem: ListTopElem,
    top: listTop,
    recalculate: recalculateTop,
  } = useListTop()

  useEffect(() => {
    recalculateTop()
  }, [showMajorTokens, showRecentSearches, recalculateTop])

  const { chainId } = useChainFromLocalStorage()
  const { getOkxTokenData } = useGetOkxTokenData(chainId as ChainId)
  const valueToken = value && getOkxTokenData(value)

  const itemGap = 4
  const scrollMargin = listTop + itemGap
  const tokensVirtualizer = useInfiniteVirtualizer({
    fetcher: {
      ...tokenFetcher,
      items: tokenFetcher.items.filter((token) => token.address !== value),
    },
    virtualizerOptions: {
      getScrollElement: () => containerRef.current as Element,
      scrollMargin,
      estimateSize: () => ITEM_HEIGHT,
      overscan: 2,
      gap: itemGap,
    },
    footerElements: {
      loading: {
        size: 50,
      },
    },
  })
  const { items, containerSize } = tokensVirtualizer

  return (
    <div
      className="relative overflow-x-hidden overflow-y-auto h-96"
      ref={containerRef}
    >
      <div
        className="overflow-hidden space-y-3"
        style={{
          height: rem(containerSize + listTop),
          width: '100%',
          position: 'relative',
        }}
      >
        {showMajorTokens && (
          <MajorTokensSelect
            onChange={(contractAddress) =>
              onChange?.(contractAddress, { method: 'major-tokens' })
            }
            onLoaded={recalculateTop}
          />
        )}
        {showRecentSearches && (
          <RecentTokensSelect
            value={valueToken?.tokenContractAddress}
            onChange={(contractAddress) =>
              onChange?.(contractAddress, { method: 'recent-searches' })
            }
            onLoaded={recalculateTop}
          />
        )}
        <div className="flex gap-1 items-center px-4 text-[#abacaf]">
          <StarCircleIcon className="w-4" />
          <span className="text-xs md:text-sm">Tokens</span>
        </div>
        {valueToken && (
          <TokenListItem
            token={{
              name: valueToken.tokenName,
              symbol: valueToken.tokenSymbol,
              logoUrl: valueToken.tokenLogoUrl,
            }}
            onClick={() => {
              onChange?.(valueToken.tokenContractAddress, { method: 'list' })
            }}
            style={{
              width: '100%',
              marginBottom: rem(itemGap),
            }}
            active
          />
        )}
        <ListTopElem style={{ marginTop: 0 }} />
        {items.map((tokenItem) => (
          <TokenListItem
            key={tokenItem.index}
            token={tokenItem.data}
            onClick={() => {
              onChange?.(tokenItem.data.address, { method: 'list' })
            }}
            style={{
              marginTop: 0,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: rem(tokenItem.size),
              transform: `translateY(${rem(tokenItem.start)})`,
            }}
          />
        ))}
        {tokensVirtualizer.loadingFooter && (
          <SpinnerBlock
            size="sm"
            style={{
              marginTop: 0,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: rem(tokensVirtualizer.loadingFooter.size),
              transform: `translateY(${rem(tokensVirtualizer.loadingFooter.start)})`,
            }}
          />
        )}
      </div>
    </div>
  )
}

type UseListTopReturnType = {
  Elem: (props: { className?: string; style?: CSSProperties }) => ReactNode
  top: number
  recalculate: () => void
}

function useListTop(): UseListTopReturnType {
  const itemStartRef = useRef<HTMLDivElement>(null)
  const topRef = useRef(0)
  const [top, setTop] = useState(0)

  const calculateHeight = useCallback(() => {
    const itemStartElem = itemStartRef.current
    if (!itemStartElem) {
      return
    }
    const newTop = itemStartElem.offsetTop
    if (newTop === topRef.current) {
      return
    }
    topRef.current = newTop
    setTop(newTop)
  }, [])

  useEffect(() => {
    const itemStartElem = itemStartRef.current
    if (!itemStartElem) {
      return
    }
    const container = itemStartElem.parentElement
    if (!container) {
      return
    }
    const resizeObserver = new ResizeObserver(() => {
      calculateHeight()
    })

    resizeObserver.observe(container)
    return () => {
      resizeObserver.disconnect()
    }
  }, [calculateHeight])

  const Elem = useCallback(
    (props: { className?: string; style?: CSSProperties }) => (
      <div ref={itemStartRef} {...props} />
    ),
    [itemStartRef]
  )

  return {
    Elem,
    top,
    recalculate: calculateHeight,
  }
}
