import { useMemo } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import { ChainId } from '@/modules/tokenList/constants/chains'
import { TokenViewTab } from '@/modules/tokenList/constants/tokens'

import { fetchAllZarklabTokens } from '../services/client/backend/fetch-all-zarklab-tokens'
import { ZarklabToken } from '../types/token'
import { flattenPages } from '../utils/flattenPages'

interface DataItemsResponse {
  data: ZarklabToken[]
  pagination?: {
    page: number
    totalPages: number
    limit: number
  }
}

const mockData = {
  zarkAIScore: 21,
}

const PAGE_LIMIT = 20

export type UseAllZarklabTokensConfig = {
  chainId?: ChainId | undefined
  tokenViewTab?: TokenViewTab
  searchQuery?: string
  enabled?: boolean
}

export const useAllZarklabTokens = (config: UseAllZarklabTokensConfig) => {
  const {
    chainId,
    tokenViewTab = 'all',
    searchQuery = '',
    enabled = true,
  } = config ?? {}

  const { data, ...otherQueryProps } = useInfiniteQuery({
    queryKey: ['zarklab-tokens', chainId, searchQuery, tokenViewTab],
    queryFn: async ({ pageParam }): Promise<DataItemsResponse> => {
      if (typeof chainId === 'undefined') {
        throw Error('Chain ID is not defined')
      }

      const response = await fetchAllZarklabTokens({
        page: pageParam,
        limit: PAGE_LIMIT,
        chainId,
        search: searchQuery,
        isHotToken: tokenViewTab === 'hot',
        isTopGainer: tokenViewTab === 'gainers',
        isNew: tokenViewTab === 'new',
      })

      console.log({ tokenViewTab })
      return {
        ...response,
        data: response?.data?.map((tokenData) => {
          const { price } = tokenData

          return {
            ...tokenData,
            ...mockData,
            price: {
              ...price,
              hourlyHistory: generateTimeseries(price.hourlyHistory),
            },
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      // Ensure lastPage and lastPage.pagination are defined
      if (lastPage && lastPage.pagination) {
        const { page, totalPages } = lastPage.pagination
        return page < totalPages ? page + 1 : undefined
      }
      return undefined // If pagination data is missing, no next page
    },
    enabled: enabled && typeof chainId !== 'undefined',
  })

  const flatData = useMemo(
    () => flattenPages(data, 'data') as ZarklabToken[],
    [data]
  ) as ZarklabToken[]

  return {
    tokens: flatData,
    ...otherQueryProps,
  }
}

const getRoundedHourTimestamp = (hoursAgo = 0) => {
  const now = new Date()
  now.setMinutes(0, 0, 0) // Round downwards to the nearest hour

  now.setHours(now.getHours() - hoursAgo)

  return now.toISOString()
}

const generateTimeseries = (history: number[]) =>
  history.map((price, index) => {
    return {
      time: getRoundedHourTimestamp(index),
      price,
    }
  })
