import { useState } from 'react'
import { useDebounceCallback, useWindowSize } from 'usehooks-ts'

import { ZarklabToken } from '@/core/types/token'
import { useSubheader } from '@/core/layouts/MainLayout/hooks/useSubheader'
import { useAllZarklabTokens } from '@/core/hooks/useAllZarklabTokens'
import { useChainFromLocalStorage } from '@/core/hooks/useChain'
import {
  InfiniteVirtualizer,
  useInfiniteWindowVirtualizer,
} from '@/core/hooks/useInfiniteVirtualizer'

import { TokenList } from '../components/TokenList'
import { getInfiniteVirtualizerOptions } from '../components/TokenListConstants'
import TokenListPageHeader from '../components/TokenListPageHeader'
import { DEFAULT_TOKEN_VIEW_TAB, TokenViewTab } from '../constants/tokens'

const TokenListPage = () => {
  const [tokenViewTab, setTokenViewTab] = useState<TokenViewTab>(
    DEFAULT_TOKEN_VIEW_TAB
  )
  const [searchText, setSearchTextChange] = useState('')
  const debouncedSetSearchTextChange = useDebounceCallback(
    setSearchTextChange,
    500
  )
  const tokensVirtualizer = useTokensVirtualizer({
    tokenViewTab,
    searchText,
  })

  const { createSubHeaderPortal } = useSubheader()

  return (
    <>
      {createSubHeaderPortal(
        <TokenListPageHeader
          tokenViewTab={tokenViewTab}
          onTokenTabChange={setTokenViewTab}
          searchText={searchText}
          onSearchTextChange={debouncedSetSearchTextChange}
        />
      )}
      <div className="w-full relative">
        <div className="mb-4">
          <TokenList tokensVirtualizer={tokensVirtualizer} />
        </div>
      </div>
    </>
  )
}

export default TokenListPage

type TokensVirtualizerOptions = {
  tokenViewTab: TokenViewTab
  searchText: string
}

function useTokensVirtualizer(
  options: TokensVirtualizerOptions
): InfiniteVirtualizer<ZarklabToken> {
  const { tokenViewTab, searchText } = options

  const { chainId } = useChainFromLocalStorage()

  const { tokens, hasNextPage, fetchNextPage, isFetching } =
    useAllZarklabTokens({ chainId, tokenViewTab, searchQuery: searchText })

  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 786
  const tokensVirtualizer = useInfiniteWindowVirtualizer({
    fetcher: {
      items: tokens,
      hasNextPage,
      fetchNextPage,
      isFetching,
    },
    ...getInfiniteVirtualizerOptions(isMobile),
  })
  return tokensVirtualizer
}
