import { FC } from 'react'

interface CardProps {
  children: React.ReactNode
  id?: string
  className?: string
  cardRef?: React.RefObject<HTMLDivElement>
}

export const Card: FC<CardProps> = ({
  children,
  id,
  className = '',
  cardRef,
}) => {
  return (
    <div
      ref={cardRef}
      id={id}
      className={`w-full p-6 bg-gradient-to-b from-[#23202F] to-[#121318] rounded-[24px] border-[rgba(255,255,255,0.15)] border ${className}`}
    >
      {children}
    </div>
  )
}
