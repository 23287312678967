import { cx } from 'class-variance-authority'

import { Badge } from './common/Badge'

import { ClockIcon } from '@/assets/icons/ClockIcon'
import { GrowthArrowIcon } from '@/assets/icons/GrowthArrow'
import { LossArrowIcon } from '@/assets/icons/LossArrow'

export const PriceChangeBadge = ({
  priceChange24h,
}: {
  priceChange24h: number
}) => {
  return (
    <Badge>
      <div className="flex">
        {priceChange24h > 0 && <GrowthArrowIcon className="mr-1" />}
        {priceChange24h < 0 && <LossArrowIcon className="mr-1" />}
        {priceChange24h === 0 && <div className="w-6" />}
        <span
          className={cx('text-xs mr-3', {
            'text-growth': priceChange24h > 0,
            'text-loss': priceChange24h < 0,
          })}
        >
          {priceChange24h > 0 && '+'}
          {priceChange24h}%
        </span>
      </div>
      <div className="flex">
        <ClockIcon className="w-4 text-[#abacaf]" />
        <span className="text-xs text-[#abacaf] ml-2">24h</span>
      </div>
    </Badge>
  )
}
