import { Amplify } from 'aws-amplify'

import { appConfig } from '@/config'

Amplify.configure({
  Auth: {
    region: appConfig.region,
    userPoolId: appConfig.userPoolId,
    userPoolWebClientId: appConfig.userPoolWebClientId,
    identityPoolId: appConfig.identityPoolId,
  },
})
