import {
  Dispatch,
  SetStateAction,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react'

export type UseStorageOptions<T> = {
  serializer?: (value: T) => string
  deserializer?: (value: string) => T
  initializeWithValue?: boolean
  listen?: boolean
}

export type UseStorageNonStringOptions<T> = UseStorageOptions<T> &
  Required<Pick<UseStorageOptions<T>, 'serializer' | 'deserializer'>>
export type StorageHookReturnType<T> = [T, Dispatch<SetStateAction<T>>]

export function makeStorageHookConstructor(storage: Storage) {
  function useStorage<T extends string>(
    key: string,
    initialValue?: T,
    options?: UseStorageOptions<T>
  ): StorageHookReturnType<T>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function useStorage<T extends Exclude<any, string>>(
    key: string,
    initialValue: T,
    options: UseStorageNonStringOptions<T>
  ): StorageHookReturnType<T>
  function useStorage<T>(
    key: string,
    initialValue: T = '' as T,
    options?: UseStorageOptions<T>
  ): [T, Dispatch<SetStateAction<T>>] {
    const {
      serializer = (value: T) => value as string,
      deserializer = (value: string) => value as T,
      initializeWithValue = true,
      listen = true,
    } = options ?? {}
    const hookId = useId()

    const [value, _setValue] = useState<T>(
      initializeWithValue
        ? getInitialValue(storage, key, initialValue, deserializer)
        : initialValue
    )
    const hasInitializedRef = useRef(initializeWithValue)

    function setValue(newValue: SetStateAction<T>) {
      _setValue((curValue) => {
        const v =
          typeof newValue === 'function'
            ? (newValue as (prevState: T) => T)(curValue)
            : newValue
        requestAnimationFrame(() => updateStorage(storage, key, serializer(v)))
        return v
      })
    }

    useEffect(() => {
      // Initialize the value on frontend
      if (!initializeWithValue) {
        _setValue(() => {
          hasInitializedRef.current = true
          return getInitialValue(storage, key, initialValue, deserializer)
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      // eslint-disable-next-line
        function changeHandler(event: any) {
        if (event.hookId === hookId) {
          return
        } else if (event.storageArea !== storage) {
          return
        } else if (event.key !== key) {
          return
        }
        _setValue(
          event.newValue === null ? initialValue : deserializer(event.newValue)
        )
      }

      window.addEventListener(
        'sync-storage',
        changeHandler as (evt: Event) => void
      )
      return () => {
        window.removeEventListener(
          'sync-storage',
          changeHandler as (evt: Event) => void
        )
      }
    }, [key, hookId, listen, initialValue, deserializer])

    useEffect(() => {
      if (!listen) {
        return
      }

      function changeHandler(event: StorageEvent) {
        if (event.storageArea !== storage) {
          return
        } else if (event.key !== key) {
          return
        }
        _setValue(
          event.newValue === null ? initialValue : deserializer(event.newValue)
        )
      }

      window.addEventListener('storage', changeHandler)
      return () => {
        window.removeEventListener('storage', changeHandler)
      }
    }, [key, listen, initialValue, deserializer])

    return [value, setValue]
  }

  return useStorage
}

function getInitialValue<T>(
  storage: Storage,
  storageKey: string,
  initialValue: T,
  deserializer: (s: string) => T
) {
  if (typeof storage[storageKey] === 'undefined') {
    return initialValue
  }
  return deserializer(storage[storageKey])
}

function updateStorage(storage: Storage, key: string, newValue: string) {
  const oldValue = storage[key]
  storage[key] = newValue
  window.dispatchEvent(
    new StorageEvent('sync-storage', {
      key,
      newValue,
      oldValue,
      storageArea: storage,
      url: location.href,
    })
  )
}
