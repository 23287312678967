import API from '../../api'

// [2024-11-11] Currently a backend proxy of the OKX endpoint at
// https://www.okx.com/web3/build/docs/waas/dex-get-quote

export type FetchQuoteOptions = {
  chainId: string | number
  amount: string | number
  fromTokenAddress: `0x${string}`
  toTokenAddress: `0x${string}`
  dexIds?: string
  priceImpactProtectionPercentage?: string
  feePercent?: string
}

export type FetchQuoteResponseToken = {
  decimal: string
  tokenContractAddress: `0x${string}`
  tokenSymbol: string
  tokenUnitPrice: string | null
}

export type FetchQuoteResponse = {
  code: string
  data: Array<{
    chainId: string
    dexRouterList: Array<{
      router: `0x${string}`
      routerPercent: string
      subRouterList: Array<{
        dexProtocol: Array<{
          percent: string
          dexName: string
        }>
        fromToken: FetchQuoteResponseToken
        toToken: FetchQuoteResponseToken
      }>
    }>
    estimateGasFee: string
    fromToken: FetchQuoteResponseToken
    fromTokenAmount: string
    toToken: FetchQuoteResponseToken
    toTokenAmount: string
    quoteCompareList: Array<{
      dexName: string
      dexLogo: string
      tradeFee: string
      amountOut: string // As of 2024-11-11, the OKX API incorrectly lists this field as `receiveAmount`
    }>
  }>
  msg: string
}

export async function fetchQuote(
  options: FetchQuoteOptions
): Promise<FetchQuoteResponse> {
  const { chainId, amount, fromTokenAddress, toTokenAddress } = options
  return await API.get('/dex/quote', {
    chainId: chainId.toString(),
    amount: BigInt(amount).toString(),
    fromTokenAddress,
    toTokenAddress,
  })
}
