import { FC, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { ChevronLeftIcon } from '@radix-ui/react-icons'
import { useAccount } from 'wagmi'

import { Button } from '@/core/components/common/Button'
import ButtonToggle from '@/core/components/common/ButtonToggle'
import { Card } from '@/core/components/common/Card'
import SpinnerBlock from '@/core/components/SpinnerBlock'
import { TransactionDialog } from '@/core/components/TransactionDialog'

import { useApproveAndSwap } from '../../hooks/useApproveAndSwap'
import {
  DEFAULT_SLIPPAGE,
  SLIPPAGE_PLACES,
  SLIPPAGES,
  useSlippage,
} from '../../hooks/useSlippage'
import { useSwapForm } from '../../hooks/useSwapForm'
import { useTokenSearchParams } from '../../hooks/useTokenSearchParams'
import { SwapFormInput } from './SwapFormInput'

import { CogIcon } from '@/assets/icons/Cog'
import { UpDownArrowsIcon } from '@/assets/icons/UpDownArrows'

export const SwapForm: FC = () => {
  const { address } = useAccount()

  const { from, to } = useTokenSearchParams()

  const {
    methods,
    fromTokenInput,
    handleSwapInputs,
    onSuccessfulTransaction,
    quote,
    isQuoteLoading,
    isQuotePending,
    fromTokenLogoUrl,
    toTokenLogoUrl,
    fromTokenValue,
    toTokenValue,
  } = useSwapForm({ from, to })

  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const { slippagePercent } = useSlippage()

  const { onApprove, isOpenDialog, setIsOpenDialog } = useApproveAndSwap({
    fromTokenInput,
    onSuccessfulTransaction,
  })

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const isSwapLoading = isQuoteLoading
  const isSwapReady = !isSwapLoading && !isQuotePending && quote && address

  return (
    <Card className="relative overflow-hidden">
      <TransactionDialog
        isOpen={isOpenDialog}
        isStepTwo={true}
        onOpenChange={setIsOpenDialog}
      />
      <FormProvider {...methods}>
        <div className="flex justify-between mb-4">
          <span className="font-medium">Swap</span>
          <Button
            variant="ghost"
            size="icon"
            aria-label="Settings"
            onClick={() => setIsSettingsOpen(true)}
          >
            <CogIcon className="cursor-pointer" />
          </Button>
        </div>
        <div className="relative flex flex-col gap-y-2 mb-4">
          <SwapFormInput
            currencyType="fromToken"
            contractAddress={from}
            iconUrl={fromTokenLogoUrl ?? ''} // TODO [JIRA:R2-34]: Add placeholder token image URL if no token found
            tokenValue={fromTokenValue}
          />
          <SwapFormInput
            currencyType="toToken"
            contractAddress={to}
            disabled={true}
            iconUrl={toTokenLogoUrl ?? ''} // TODO [JIRA:R2-34]: Add placeholder token image URL if no token found
            tokenValue={toTokenValue}
          />
          <span
            className="w-10 h-10 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-secondary rounded-full p-3 cursor-pointer"
            onClick={handleSwapInputs}
          >
            <UpDownArrowsIcon />
          </span>
        </div>
        {!!errors &&
          Object.entries(errors).map(([key, entry]) => (
            <div className="text-xs text-danger" key={key}>
              {entry.message}
            </div>
          ))}
        <div className="flex justify-between text-sm mt-3 mb-6">
          <span className="text-gray-400">Slippage:</span>
          <span>{slippagePercent}%</span>
        </div>
        <Button
          disabled={!isSwapReady}
          hideDisabled={isSwapLoading}
          className="w-full h-16 uppercase font-medium"
          onClick={handleSubmit(() => onApprove())}
        >
          {isSwapLoading ? (
            <SpinnerBlock size="sm" color="white" className="p-0 h-full" />
          ) : (
            <>Swap</>
          )}
        </Button>
        {isSettingsOpen && (
          <SwapFormSettings onBack={() => setIsSettingsOpen(false)} />
        )}
      </FormProvider>
    </Card>
  )
}

type SwapFormSettingsProps = {
  onBack: () => void
}

const SwapFormSettings: FC<SwapFormSettingsProps> = (props) => {
  const { onBack } = props

  const {
    slippageThousandth,
    setSlippageThousandth,
    slippagePercent,
    setSlippagePercent,
  } = useSlippage()
  const [isCustomSlippage, setIsCustomSlippage] = useState(
    !(SLIPPAGES as readonly number[]).includes(slippageThousandth)
  )
  const [customSlippageText, setCustomSlippageText] = useState(
    isCustomSlippage
      ? slippagePercent.toString()
      : ((DEFAULT_SLIPPAGE / SLIPPAGE_PLACES) * 100).toString()
  )

  useEffect(() => {
    if (!isCustomSlippage) {
      return
    }
    setSlippagePercent(Number(customSlippageText))
  }, [isCustomSlippage, customSlippageText, setSlippagePercent])

  return (
    <div className="absolute inset-0 p-6 bg-gradient-to-b from-[#23202F] to-[#121318]">
      <div className="relative">
        <Button
          className="absolute x-0 y-0 pt-1"
          variant="secondary-link"
          size="secondary-link"
          onClick={onBack}
        >
          <ChevronLeftIcon className="mr-2" />
          Back
        </Button>
        <div className="font-medium text-center">Settings</div>
      </div>
      <div className="mt-2">
        <div>
          <div className="text-xs">Slippage tolerance</div>
          <div className="mt-2">
            <ButtonToggle
              value={{
                custom: isCustomSlippage,
                value: isCustomSlippage
                  ? customSlippageText
                  : slippageThousandth.toString(),
              }}
              onChange={(v, method) => {
                setSlippageThousandth(Number(v))
                setIsCustomSlippage(method === 'custom')
              }}
              buttons={[
                ...SLIPPAGES.map((slippage) => ({
                  id: slippage.toString(),
                  label: `${(slippage * 100) / SLIPPAGE_PLACES}%`,
                })),
              ]}
              custom={{
                label: 'Custom',
                'aria-label': 'Set custom slippage',
                inputProps: {
                  type: 'number',
                  defaultValue: 1,
                  step: 0.1,
                  min: 0,
                  max: 100,
                  className: 'leading-4',
                  onChange: (event) => {
                    const value = event.currentTarget.value
                    if (!value.match(/^\d{0,3}(\.(\d?)?)?$/)) {
                      event.preventDefault()
                      return
                    }
                    const valueNumber = Number(value)
                    if (valueNumber < 0 || valueNumber > 100) {
                      event.preventDefault()
                      return
                    }
                    setCustomSlippageText(value)
                    event.preventDefault() // bypass the default onChange handler
                  },
                },
                inputContainerProps: {
                  className: "after:absolute after:content-['%'] after:right-1",
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
