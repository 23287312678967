import { useQuery } from '@tanstack/react-query'

import { ChainId } from '@/modules/tokenList/constants/chains'

import { fetchAllOkxTokens } from '../services/client/okx/fetch-all-okx-tokens'

export type UseOkxTokensConfig = {
  enabled?: boolean
}

export const useOkxTokens = (
  chainId?: ChainId | undefined,
  config?: UseOkxTokensConfig
) => {
  const { enabled = true } = config ?? {}

  const { data, ...otherQueryProps } = useQuery({
    queryKey: ['okxTokens', chainId],
    queryFn: async () => {
      if (typeof chainId === 'undefined') {
        throw Error('Chain ID is not defined')
      }

      const { data } = await fetchAllOkxTokens({ chainId })
      return data
    },
    enabled: enabled && typeof chainId !== 'undefined',
  })

  return { data, ...otherQueryProps }
}

export type UseOkxTokensReturnType = ReturnType<typeof useOkxTokens>
