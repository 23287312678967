import { FC } from 'react'

import { ZarklabToken } from '@/core/types/token'
import SpinnerBlock from '@/core/components/SpinnerBlock'
import { InfiniteVirtualizer } from '@/core/hooks/useInfiniteVirtualizer'
import { rem } from '@/core/utils/utils'

import { TokenListRow } from './TokenListRow'

import { InfoIcon } from '@/assets/icons/Info'

export interface TokenListProps {
  tokensVirtualizer: InfiniteVirtualizer<ZarklabToken>
}

export const TokenList: FC<TokenListProps> = (props) => {
  const { tokensVirtualizer } = props

  return (
    <div>
      <div className="!w-[100vw] md:!w-full -mx-4 md:mx-0 flex justify-between px-4 md:px-3 sticky top-[68px] md:top-[72px] bg-[#272531] z-20 py-3">
        <div className="min-w-36 md:w-48">Token</div>
        <div className="hidden md:flex flex-1 justify-evenly items-center">
          <div className="w-28 flex items-center text-sm mr-2">
            <InfoIcon className="w-4 h-4 mr-1" />
            Zark AI score
          </div>
          <div className="w-24 text-sm">Market Cap</div>
          <div className="w-24 text-sm">Volume 24h</div>
          <div className="w-32 text-sm">Price</div>
        </div>
        <div className="hidden md:block w-40 lg:w-64 -ml-4" />
        <div className="min-w-24 md:hidden">Price</div>
        <div className="w-24 md:hidden flex items-center">
          <InfoIcon className="w-4 h-4 mr-1" />
          Zark AI
        </div>
      </div>
      <div
        className="!w-[100vw] md:!w-full -mx-4 md:mx-0 overflow-hidden divide-y divide-cool-gray-850"
        style={{
          height: rem(tokensVirtualizer.containerSize),
          width: '100%',
          position: 'relative',
        }}
      >
        {tokensVirtualizer.items.map((tokenItem) => (
          <TokenListRow
            key={tokenItem.index}
            token={tokenItem.data}
            className="absolute top-0 left-0 w-full"
            style={{
              height: rem(tokenItem.size),
              transform: `translateY(${rem(tokenItem.start)})`,
            }}
          />
        ))}
        {tokensVirtualizer.loadingFooter && (
          <SpinnerBlock
            size="sm"
            className="absolute top-0 left-0 w-full !border-0"
            style={{
              height: rem(tokensVirtualizer.loadingFooter.size),
              transform: `translateY(${rem(tokensVirtualizer.loadingFooter.start)})`,
            }}
          />
        )}
        {tokensVirtualizer.emptyFooter && (
          <div
            className="absolute top-0 left-0 w-full !border-0 flex justify-center items-center"
            style={{
              height: rem(tokensVirtualizer.emptyFooter.size),
              transform: `translateY(${rem(tokensVirtualizer.emptyFooter.start)})`,
            }}
          >
            No tokens found. Adjust your search filters to view more tokens.
          </div>
        )}
        {tokensVirtualizer.endedFooter && (
          <div
            className="absolute top-0 left-0 w-full !border-0 flex justify-center items-center"
            style={{
              height: rem(tokensVirtualizer.endedFooter.size),
              transform: `translateY(${rem(tokensVirtualizer.endedFooter.start)})`,
            }}
          >
            End of list
          </div>
        )}
      </div>
    </div>
  )
}
