import { FC, SVGProps } from 'react'

export const ClockIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 8.66667H7.99999C7.63199 8.66667 7.33333 8.36867 7.33333 8V5.33334C7.33333 4.96467 7.63199 4.66667 7.99999 4.66667C8.36799 4.66667 8.66666 4.96467 8.66666 5.33334V7.33334H10.6667C11.0353 7.33334 11.3333 7.63134 11.3333 8C11.3333 8.36867 11.0353 8.66667 10.6667 8.66667ZM7.99999 1.33334C4.32399 1.33334 1.33333 4.324 1.33333 8C1.33333 11.676 4.32399 14.6667 7.99999 14.6667C11.676 14.6667 14.6667 11.676 14.6667 8C14.6667 4.324 11.676 1.33334 7.99999 1.33334Z"
        fill="currentColor"
      />
    </svg>
  )
}
