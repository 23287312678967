import { FC } from 'react'

import { Badge } from '@/core/components/common/Badge'
import { Button } from '@/core/components/common/Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/core/components/common/Dialog'
import { useCommon } from '@/core/hooks/useCommon'
import { formatCurrency } from '@/core/utils/formatCurrency'

import { appConfig } from '@/config'

interface UnlockTradingSignalsDialogProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  price: number
  pay: () => void
}

export const UnlockTradingSignalsDialog: FC<
  UnlockTradingSignalsDialogProps
> = ({ isOpen, setIsOpen, price, pay }) => {
  const { balance } = useCommon()
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        aria-describedby={''}
        className={
          'bg-cool-gray-900 border border-cool-gray-850 rounded-md shadow-lg p-6'
        }
        dialogCloseClassName="right-5 top-5 md:top-5"
      >
        <DialogHeader>
          <DialogTitle className="flex items-center space-x-2">
            <span className="text-white text-3xl">
              Pay to unlock trading signals
            </span>
          </DialogTitle>
        </DialogHeader>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </p>
        <div className="md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
          <div className="text-center md:text-left text-3xl font-bold">
            {formatCurrency(String(price))} {appConfig.currency}
          </div>
          <div className="text-center md:text-right text-xs">
            <Badge>
              <span className="text-gray-400 pr-2">Your Balance:</span>
              {balance.formatted} {balance.symbol}
            </Badge>
          </div>
          <div>
            <Button
              className="w-full"
              onClick={() => {
                pay()
                setIsOpen(false)
              }}
            >
              Unlock Now
            </Button>
          </div>
          <div>
            <Button
              className="w-full"
              variant="outline"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
