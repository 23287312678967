import { Card } from '@/core/components/common/Card'
import { formatCurrency } from '@/core/utils/formatCurrency'

import { useTokenDetails } from '../../hooks/useTokenDetails'

export const Stats = () => {
  const { tokenDetails } = useTokenDetails()
  const { marketCap, volume24h, totalSupply, symbol } = tokenDetails ?? {}

  return (
    <Card className="w-full h-full relative">
      <div className="py-2">
        <span className="flex font-medium">Stats</span>

        <div className="grid gap-2">
          <StatsInfo name="Popularity" amount="5.0" suffix="%" />
          <StatsInfo name="Market Cap" amount={marketCap} suffix="USD" />
          <StatsInfo name="Volume (24h)" amount={volume24h} suffix="USD" />
          <StatsInfo name="Total Supply" amount={totalSupply} suffix={symbol} />
        </div>
      </div>
    </Card>
  )
}

const StatsInfo = ({
  name,
  amount,
  suffix = '',
}: {
  name: string
  amount: string | undefined
  suffix?: string
}) => {
  return (
    amount && (
      <div className="flex justify-between rounded-2xl bg-gray-850 py-3 px-3">
        <span className="text-gray-550">{name}:</span>
        <span>{`${formatCurrency(amount || '0', 0, 0)} ${suffix}`}</span>
      </div>
    )
  )
}
