import { FC } from 'react'

type GrowthArrowIconProps = {
  className?: string
}

export const GrowthArrowIcon: FC<GrowthArrowIconProps> = ({
  className = '',
}) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9948 4.64193C13.9935 4.59393 13.9795 4.5486 13.9668 4.5026C13.9568 4.46393 13.9521 4.4246 13.9355 4.38926C13.9201 4.3546 13.8941 4.3266 13.8715 4.2946C13.8435 4.25393 13.8181 4.21326 13.7821 4.17993C13.7761 4.17393 13.7735 4.16593 13.7668 4.1606C13.7428 4.13993 13.7135 4.1326 13.6875 4.11593C13.6475 4.08993 13.6081 4.06326 13.5628 4.04593C13.5181 4.02993 13.4741 4.02593 13.4281 4.01926C13.3961 4.0146 13.3675 3.99993 13.3335 3.99993H10.0001C9.63146 3.99993 9.33347 4.2986 9.33347 4.6666C9.33347 5.0346 9.63146 5.33326 10.0001 5.33326H11.8841L9.19347 8.47193L6.3428 6.76193C6.0608 6.59126 5.69813 6.65393 5.48746 6.9066L2.15413 10.9066C1.9188 11.1893 1.9568 11.6099 2.23947 11.8453C2.3648 11.9493 2.51547 11.9999 2.66613 11.9999C2.85747 11.9999 3.0468 11.9186 3.1788 11.7599L6.1468 8.1986L8.99013 9.90526C9.26946 10.0726 9.62813 10.0139 9.83947 9.76726L12.6668 6.4686V7.99993C12.6668 8.36793 12.9648 8.6666 13.3335 8.6666C13.7021 8.6666 14.0001 8.36793 14.0001 7.99993V4.6666C14.0001 4.65793 13.9955 4.6506 13.9948 4.64193Z"
        fill="#1DEE6A"
      />
    </svg>
  )
}
