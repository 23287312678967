export const getRandomString = (bytes: number) => {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()'
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)

  return Array.from(randomValues)
    .map((item: number) => {
      return charset[item % charset.length]
    })
    .join('')
    .concat('Aa1*')
    .concat(new Date().getTime().toString())
}
