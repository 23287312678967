import { useEffect, useRef } from 'react'
import { ChevronRightIcon } from '@radix-ui/react-icons'
import { ConnectButton as Connect } from '@rainbow-me/rainbowkit'
import { useAccount, useDisconnect } from 'wagmi'

import { Button } from '@/core/components/common/Button'
import { useCommon } from '@/core/hooks/useCommon'

import { useAuth } from '../hooks/useAuth'

import { queryClient } from '@/config'
import { getCognitoUserName, signUserOut } from '@/services/auth'

export const ConnectButton = ({
  className = '',
  onClick,
}: {
  className?: string
  onClick?: () => void
}) => {
  const { disconnectAsync } = useDisconnect()
  const { isConnected, address } = useAccount()
  const { balance } = useCommon()
  const { signIn } = useAuth()
  const prevValueRef = useRef()
  const username = getCognitoUserName()

  // A signature is required when linking a wallet for the first time.
  useEffect(() => {
    if (address && !username) {
      signIn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, username])

  // The user has linked the wallet but switches to a different wallet address to sign again
  useEffect(() => {
    const prevValue = prevValueRef.current

    if (prevValue !== undefined && prevValue !== address && isConnected) {
      signIn()
    }

    prevValueRef.current = address as undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected])

  useEffect(() => {
    if (!isConnected) {
      const queriesToKeep = ['zarklab-tokens', 'chain']

      const allQueries = queryClient.getQueryCache().getAll()
      allQueries.forEach((query) => {
        if (!queriesToKeep.includes(String(query.queryKey[0]))) {
          queryClient.removeQueries({ queryKey: query.queryKey })
        }
      })
    }
  }, [isConnected])

  // Uses wagmi to disconnect the user's wallet
  const disconnectWallet = async () => {
    try {
      await disconnectAsync()
    } catch (error) {
      console.error(error, 'Wagmi disconnect wallet error', error)
    }
  }
  // Disconnects the user's wallet and signs them out
  const onSignOut = async () => {
    await disconnectWallet()
    await signUserOut()
  }

  useEffect(() => {
    if (!isConnected) {
      onSignOut()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected])

  return (
    <Connect.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading'
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated')

        return (
          <div
            className={className}
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    className="h-9 md:h-10 text-xxs md:text-sm"
                    variant="outline"
                    onClick={() => {
                      openConnectModal()
                      onClick?.()
                    }}
                    type="button"
                  >
                    <span className="flex items-center">
                      <span>CONNECT WALLET</span> <ChevronRightIcon />
                    </span>
                  </Button>
                )
              }
              if (chain.unsupported) {
                return (
                  <Button
                    className="h-9 md:h-10 text-xxs md:text-sm"
                    variant="destructive"
                    onClick={() => {
                      openChainModal()
                      onClick?.()
                    }}
                    type="button"
                  >
                    <span className="animate-pulse">Wrong network</span>
                  </Button>
                )
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <Button
                    className="h-9 md:h-10 text-xxs md:text-sm"
                    variant="outline"
                    onClick={openAccountModal}
                    type="button"
                  >
                    <span className="pr-2 font-semibold">
                      {balance.formatted} {balance.symbol}
                    </span>
                    <span className="text-cool-gray-300">
                      {account.displayName}
                    </span>
                  </Button>
                </div>
              )
            })()}
          </div>
        )
      }}
    </Connect.Custom>
  )
}
