import { FC } from 'react'

type UpDownArrowsIconProps = {
  className?: string
}

export const UpDownArrowsIcon: FC<UpDownArrowsIconProps> = ({
  className = '',
}) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.80643 3.59152L2.86189 3.52871L4.86194 1.52871C4.87995 1.5107 4.89898 1.49372 4.91895 1.47786L4.99979 1.42272L5.04865 1.39712L5.10668 1.37298L5.17765 1.35174L5.2556 1.33794L5.31134 1.33381L5.37272 1.33461L5.4499 1.34365L5.54146 1.36661L5.61552 1.3959L5.68051 1.43078L5.74455 1.47531L5.80478 1.52871L7.80483 3.52871C8.06519 3.78906 8.06519 4.21117 7.80483 4.47152C7.5645 4.71185 7.18634 4.73033 6.9248 4.52698L6.862 4.47152L6.0001 3.61012L6.00005 10.0001C6.00005 10.342 5.74269 10.6238 5.41113 10.6623L5.33334 10.6668C4.96515 10.6668 4.66668 10.3683 4.66668 10.0001L4.66676 3.61012L3.80473 4.47152C3.5644 4.71185 3.18623 4.73033 2.9247 4.52698L2.86189 4.47152C2.64159 4.25123 2.6077 3.91511 2.76022 3.65918L2.80643 3.59152ZM13.1382 12.4716L11.1381 14.4716L11.0635 14.536L10.9896 14.5836L10.9141 14.6195L10.8439 14.6431L10.7832 14.6567L10.7061 14.6657L10.6273 14.6657L10.5503 14.6567L10.4582 14.6336L10.4222 14.6206L10.3641 14.5944L10.3196 14.5695L10.2656 14.5328L10.223 14.4979L10.1953 14.4716L8.19523 12.4716L8.13977 12.4088C7.95336 12.1691 7.95336 11.8314 8.13977 11.5916L8.19523 11.5288L8.25803 11.4734C8.49777 11.287 8.83551 11.287 9.07525 11.4734L9.13806 11.5288L10.0001 12.3902L10 6.00022C10 5.65833 10.2574 5.37655 10.5889 5.33804L10.6667 5.33356L10.7445 5.33804C11.0505 5.37359 11.2933 5.61642 11.3289 5.92247L11.3334 6.00022L11.3334 12.3902L12.1953 11.5288L12.2581 11.4734C12.5197 11.27 12.8978 11.2885 13.1382 11.5288C13.3785 11.7691 13.397 12.1473 13.1936 12.4088L13.1382 12.4716Z"
        fill="white"
      />
    </svg>
  )
}
