import OkxAPI from './api'
import { OkxToken } from './types'

export type FetchAllOkxTokensOptions = {
  chainId: number | string
}

export type FetchAllOkxTokensResponse = {
  code: string
  message: string
  data: OkxToken[]
}

export async function fetchAllOkxTokens(
  options: FetchAllOkxTokensOptions
): Promise<FetchAllOkxTokensResponse> {
  const { chainId } = options

  return await OkxAPI.get(`/dex/aggregator/all-tokens`, {
    chainId: chainId.toString(),
  })
}
