import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { createChart } from 'lightweight-charts'

import { TokenAddress } from '@/core/types/token'
import { Button } from '@/core/components/common/Button'
import { Card } from '@/core/components/common/Card'
import { PriceChangeBadge } from '@/core/components/PriceChangeBadge'
import { useChainFromRoute } from '@/core/hooks/useChain'

import { ChainId } from '@/modules/tokenList/constants/chains'

import { useChart } from '../../hooks/useChart'
import { useTokenData } from '../../hooks/useTokenData'

export const Chart = () => {
  const { contractAddress } = useParams<{ contractAddress: TokenAddress }>()
  const { chainId } = useChainFromRoute()
  const { data: tokenData } = useTokenData(
    chainId as ChainId,
    contractAddress as TokenAddress
  )

  const {
    data,
    onChangeInterval,
    config,
    areaSeriesConfig,
    intervals,
    dateInterval,
    intervalColors,
    seriesData,
  } = useChart({ contractAddress })
  const chartContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const chart = createChart(chartContainerRef.current || '', config)

    const areaSeries = chart.addAreaSeries(areaSeriesConfig)
    chart.addLineSeries({
      color: intervalColors[dateInterval],
    })

    areaSeries.setData(data)

    chart.timeScale().fitContent()

    return () => chart.remove()
  }, [data, config, areaSeriesConfig, intervalColors, dateInterval, seriesData])

  return (
    <Card className="w-full h-full relative">
      <div className="absolute top-5 left-0 z-10 flex flex-row flex-wrap w-full px-6">
        <div className="basis-1/2">
          <div>
            <img
              className="size-8 mr-3 float-left mt-1"
              src={tokenData?.tokenLogoUrl}
              alt={tokenData?.tokenName}
            />
            <p>{tokenData?.tokenSymbol}</p>
            <p className="text-gray-400 text-xs">{tokenData?.tokenName}</p>
          </div>
        </div>
        <div className="flex justify-end items-start basis-1/2">
          {intervals.map((interval) => (
            <Button
              size="md"
              variant={dateInterval === interval ? 'default' : 'underline'}
              key={interval}
              onClick={() => onChangeInterval(interval)}
            >
              {interval}
            </Button>
          ))}
        </div>
        <div className="flex basis-1/4">
          <div className="flex flex-row flex-1">
            <div className="md:text-2xl font-bold pr-4 text-nowrap">
              3,000.00 USD
            </div>
            <PriceChangeBadge priceChange24h={-0.5} />
          </div>
        </div>
      </div>
      <div
        className="w-full h-full min-h-40 pt-12 md:h-[19.5rem] md:pt-10"
        ref={chartContainerRef}
      />
    </Card>
  )
}
