import { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { TokenAddress } from '@/core/types/token'
import { useChainFromRoute } from '@/core/hooks/useChain'
import { useRecentTokens } from '@/core/hooks/useRecentTokens'

import { TokenListItem } from './TokenListItem'

import { ClockIcon } from '@/assets/icons/ClockIcon'

type RecentTokensSelectProps = {
  value?: TokenAddress
  onChange?: (contractAddress: TokenAddress) => void
  onLoaded?: () => void
}

export const RecentTokensSelect: FC<RecentTokensSelectProps> = (
  props: RecentTokensSelectProps
) => {
  const { value, onChange, onLoaded } = props

  const { chainId } = useChainFromRoute()

  const { contractAddress: currentTokenAddress } = useParams()

  const { isLoading, recentTokens } = useRecentTokens(chainId)

  useEffect(() => {
    if (isLoading) {
      onLoaded?.()
    }
  }, [isLoading, onLoaded])

  if (isLoading || recentTokens.length === 0) {
    return null
  }

  return (
    <>
      <div className="flex gap-1 items-center px-4 text-[#abacaf]">
        <ClockIcon className="w-4" />
        <span className="text-xs md:text-sm">Recent searches</span>
      </div>
      <div className="flex gap-1 flex-col">
        {recentTokens
          .filter((token) => token.tokenContractAddress !== currentTokenAddress)
          .map((token) => (
            <TokenListItem
              key={token.tokenContractAddress}
              token={{
                name: token.tokenName,
                symbol: token.tokenSymbol,
                logoUrl: token.tokenLogoUrl,
              }}
              onClick={() => onChange?.(token.tokenContractAddress)}
              active={token.tokenContractAddress === value}
            />
          ))}
      </div>
    </>
  )
}
