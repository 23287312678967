import { Dispatch, SetStateAction, useState } from 'react'
import { useAccount, useSendTransaction } from 'wagmi'

import { fetchApproveTransaction } from '@/core/services/client/backend/fetch-approve-transaction'
import { fetchSwap } from '@/core/services/client/backend/fetch-swap'
import { TokenAddress } from '@/core/types/token'
import { useAllowance } from '@/core/hooks/useAllowance'
import { useChainFromRoute } from '@/core/hooks/useChain'
import { useSynchronizeChain } from '@/core/hooks/useSynchronizeChain'
import { useToast } from '@/core/hooks/useToast'
import { parseError } from '@/core/utils/parseError'

import { useQuote } from '@/modules/tokenDetails/hooks/useQuote'
import { useTokenData } from '@/modules/tokenDetails/hooks/useTokenData'
import { useTokenSearchParams } from '@/modules/tokenDetails/hooks/useTokenSearchParams'
import { ChainId } from '@/modules/tokenList/constants/chains'

import { useSlippage } from './useSlippage'

const APPROVAL_MULTIPLE = 50 // we approve 50x the initial transaction amount

export type UseApproveSwapOptions = {
  fromTokenInput: number
  onSuccessfulTransaction: () => void
}

export type UseApproveAndSwapReturnType = {
  loading: boolean
  onApprove: () => void
  isOpenDialog: boolean
  setIsOpenDialog: Dispatch<SetStateAction<boolean>>
}

export const useApproveAndSwap = (
  options: UseApproveSwapOptions
): UseApproveAndSwapReturnType => {
  const { fromTokenInput, onSuccessfulTransaction } = options

  const { toast } = useToast()

  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const { from, to } = useTokenSearchParams()

  const { address } = useAccount()
  const { slippage } = useSlippage()

  const { chainId } = useChainFromRoute()
  const { synchronizeChain } = useSynchronizeChain()

  const { sendTransactionAsync } = useSendTransaction()

  const { data: fromToken } = useTokenData(
    chainId as ChainId,
    from as TokenAddress
  )
  const { data: toToken } = useTokenData(chainId as ChainId, to as TokenAddress)

  const { allowance } = useAllowance({
    tokenAddress: fromToken?.tokenContractAddress,
  })

  const fromTokenDecimalPlaces = Number(fromToken?.decimals)

  const { isPending, data: quote } = useQuote({
    chainId: chainId as ChainId,
    amount: fromTokenInput * 10 ** fromTokenDecimalPlaces,
    fromTokenAddress: fromToken?.tokenContractAddress,
    toTokenAddress: toToken?.tokenContractAddress,
  })

  if (isPending || !quote) {
    return {
      loading: true,
      onApprove: () => undefined,
      isOpenDialog,
      setIsOpenDialog,
    }
  }

  const {
    fromTokenAmount,
    fromToken: fromTokenData,
    toToken: toTokenData,
  } = quote

  const onApprove = async () => {
    await synchronizeChain()

    if (typeof chainId === 'undefined') {
      return
    }
    if (!fromToken) {
      return
    }

    if ((allowance || 0n) <= BigInt(fromTokenAmount)) {
      const approveAmount = BigInt(
        APPROVAL_MULTIPLE * fromTokenInput * 10 ** fromTokenDecimalPlaces
      ).toString()

      try {
        setIsOpenDialog(true)

        const approveTransactionData = await fetchApproveTransaction({
          chainId,
          tokenContractAddress: fromToken.tokenContractAddress,
          approveAmount,
        })

        const result = await sendTransactionAsync({
          to: fromToken?.tokenContractAddress,
          data: approveTransactionData.data,
          value: BigInt(0),
        })

        if (result) {
          onSwap()
        }
      } catch (error) {
        console.error('Approve error: ', error)
        parseError(error).then((parsedError) =>
          toast({
            variant: 'destructive',
            title: 'Uh oh!, approve aborted',
            description: parsedError || 'An error occurred',
          })
        )
      } finally {
        setIsOpenDialog(false)
      }
    } else {
      onSwap()
    }
  }

  const onSwap = async () => {
    try {
      setIsOpenDialog(true)

      await synchronizeChain()

      const swapData = await fetchSwap({
        chainId: String(chainId),
        fromTokenAddress: fromTokenData?.tokenContractAddress,
        toTokenAddress: toTokenData?.tokenContractAddress,
        amount: fromTokenAmount,
        slippage,
        userWalletAddress: address as TokenAddress,
      })

      await sendTransactionAsync({
        to: swapData.to,
        data: swapData.data,
        value: BigInt(swapData.value),
      })

      toast({
        title: 'Success',
        description: 'Transaction submitted',
      })
      onSuccessfulTransaction()
    } catch (error) {
      console.error('Swap error: ', error)
      parseError(error).then((parsedError) =>
        toast({
          variant: 'destructive',
          title: 'Uh oh!, swap aborted',
          description: parsedError || 'An error occurred',
        })
      )
    } finally {
      setIsOpenDialog(false)
    }
  }

  return {
    loading: false,
    onApprove,
    isOpenDialog,
    setIsOpenDialog,
  }
}
