import { useLocation, useParams } from 'react-router-dom'

import {
  Chain,
  ChainId,
  CHAINS,
  DEFAULT_CHAIN_ID,
  getChainIdFromUrlKey,
} from '@/modules/tokenList/constants/chains'

import { makeLocalStorageHook } from './useStorage/makeStorageHook'

const useChainIdFromLocalStorage = makeLocalStorageHook<ChainId>(
  'APPVAR_chain',
  DEFAULT_CHAIN_ID,
  {
    serializer: (v) => v.toString(),
    deserializer: (v) =>
      Number(v) in CHAINS ? (Number(v) as ChainId) : DEFAULT_CHAIN_ID,
  }
)

function useChainIdFromRoute() {
  const { chain: urlKey } = useParams()
  const chainId = getChainIdFromUrlKey(urlKey as string)
  return chainId
}

export const useDynamicChainId = () => {
  const chainIdFromRoute = useChainIdFromRoute()
  const [chainIdFromLocalStorage] = useChainIdFromLocalStorage()

  const { pathname } = useLocation()
  if (pathname === '/') {
    return chainIdFromLocalStorage
  } else if (pathname.match(/^\/token-details(\/|$)/)) {
    return chainIdFromRoute
  }
  console.warn('Warning: attempting to get chain ID on unsupported page')
  return undefined
}

// `makeUseChain()` helps to wrap the logic for taking the chain ID and turning
// it into the Chain data. Different pages require a different chainId source.
// For instance, the token list page uses chainId from localStorage, whereas the
// token details page gets the chainId from the URL.
//
// We then utilise `makeUseChain` to make different hooks that use different
// chainId sources, and retrieve the chain accordingly.

type ChainFields = 'chainId' | 'chain'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AdditionalChainFields = Record<Exclude<string, ChainFields>, any>

function makeUseChain(
  useChainHook: () => ChainId | undefined,
  getChainId?: undefined,
  fields?: undefined
): () => { chainId: ChainId | undefined; chain: Chain | undefined }
function makeUseChain<HookReturnType>(
  useChainHook: () => HookReturnType,
  getChainId: (result: HookReturnType) => ChainId | undefined,
  fields?: undefined
): () => { chainId: ChainId | undefined; chain: Chain | undefined }
function makeUseChain<HookReturnType, Fields extends AdditionalChainFields>(
  useChainHook: () => HookReturnType,
  getChainId: (result: HookReturnType) => ChainId | undefined,
  fields: { [K in keyof Fields]: (result: HookReturnType) => Fields[K] }
): () => { chainId: ChainId | undefined; chain: Chain | undefined } & Fields
function makeUseChain<HookReturnType, Fields extends AdditionalChainFields>(
  useChainHook: () => HookReturnType,
  getChainId?: (result: HookReturnType) => ChainId | undefined,
  fields?: { [K in keyof Fields]: (result: HookReturnType) => Fields[K] }
): () => { chainId: ChainId | undefined; chain: Chain | undefined } & Fields {
  function useChain() {
    const chainResult = useChainHook()
    let chainId: ChainId | undefined = getChainId
      ? getChainId(chainResult)
      : (chainResult as ChainId | undefined)

    if (chainId && !(chainId in CHAINS)) {
      chainId = undefined
    }

    const chain =
      typeof chainId === 'undefined' || chainId === null
        ? undefined
        : CHAINS[chainId as keyof typeof CHAINS]

    const mappedFields = fields
      ? (Object.keys(fields)
          .map((key) => {
            const transform = fields[key]
            const value = transform(chainResult)
            return { [key]: value }
          })
          .reduce((a, b) => ({ ...a, ...b }), {}) as Fields)
      : ({} as Fields)

    return { chainId, chain, ...mappedFields }
  }
  return useChain
}

export const useChainFromRoute = makeUseChain(useChainIdFromRoute)

export const useChainFromLocalStorage = makeUseChain(
  useChainIdFromLocalStorage,
  ([chainId]) => chainId,
  {
    setChainId: ([, setChainId]) => setChainId,
  }
)

export const useDynamicChain = makeUseChain(useDynamicChainId)
