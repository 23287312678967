import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/core/utils/classNames'

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-2 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-[rgba(255,255,255,0.04)] text-primary-foreground shadow font-semibold',
        success:
          'border-transparent bg-success text-destructive-foreground hover:bg-success/80 font-bold',
        danger:
          'border-transparent bg-danger text-destructive-foreground shadow hover:bg-danger/80 font-bold',
        outline: 'text-foreground border-cool-gray-300 font-semibold',
        neutral:
          'border-transparent bg-cool-gray-500 text-primary-foreground shadow hover:bg-cool-gray-500/80 font-semibold',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge }
