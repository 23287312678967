import { CSSProperties, FC } from 'react'

import { cn } from '@/core/utils'
import { rem } from '@/core/utils/utils'

type TokenListItemProps = {
  token: {
    name: string
    symbol: string
    logoUrl: string
  }
  onClick?: () => void
  className?: string
  style?: CSSProperties
  active?: boolean
}

export const ITEM_HEIGHT = 46

export const TokenListItem: FC<TokenListItemProps> = (props) => {
  const { token, onClick, className, style, active = false } = props

  const { logoUrl, symbol, name } = token

  return (
    <>
      <button
        onClick={onClick}
        className={cn(
          'md:flex items-center justify-between px-4 hidden cursor-pointer rounded-2xl hover:bg-[#31353D] transition-[background] transform-gpu duration-200 ease-in-out',
          active && 'bg-[#31353D]',
          className
        )}
        style={{
          height: rem(ITEM_HEIGHT),
          ...style,
        }}
      >
        <div className="flex items-center">
          <img className="w-8 h-8 mr-3" src={logoUrl} />
          <div className="flex flex-col items-start">
            <span className="text-sm">{symbol}</span>
            <span className="text-xs text-[#abacaf]">{name}</span>
          </div>
        </div>
      </button>
      <button
        onClick={onClick}
        className={cn(
          'flex items-center justify-between px-4 md:hidden cursor-pointer rounded-2xl hover:bg-[#31353D] transform-gpu duration-200 ease-in-out',
          className
        )}
        style={{
          height: rem(ITEM_HEIGHT),
          ...style,
        }}
      >
        <div className="min-w-36 h-full flex flex-col pr-4">
          <div className="h-full flex items-center">
            <img className="w-6 h-6 mr-2" src={logoUrl} />
            <div className="flex flex-col items-start">
              <span className="text-xs">{symbol}</span>
              <span className="text-xs text-[#abacaf]">{name}</span>
            </div>
          </div>
        </div>
      </button>
    </>
  )
}
