// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import resolveConfig from 'tailwindcss/resolveConfig'

import partialTailwindConfig from '../tailwind.config.js'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tailwindConfig = resolveConfig(partialTailwindConfig as any)
export default tailwindConfig
