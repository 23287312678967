import { useAccount, useReadContract } from 'wagmi'

import { useChainFromRoute } from '@/core/hooks/useChain'

import { CHAINS } from '@/modules/tokenList/constants/chains'

import { TokenAddress } from '../types/token'

import { tokenAbi } from '@/abi/tokenAbi'

export const useAllowance = ({
  tokenAddress,
}: {
  tokenAddress?: TokenAddress | undefined
}) => {
  const { address } = useAccount()
  const { chainId } = useChainFromRoute()

  const id = Number(chainId) as keyof typeof CHAINS
  const spenderAddress = CHAINS[id]['spenderAddress']

  const { data: allowance } = useReadContract({
    abi: tokenAbi,
    address: tokenAddress,
    args: [address as TokenAddress, spenderAddress],
    functionName: 'allowance',
    query: { enabled: !!address },
  })

  return { allowance }
}
