import { DependencyList, useEffect, useRef, useState } from 'react'

export type DebouncedDependencyList = Array<
  unknown | DebouncedDependency<unknown>
>

class DebouncedDependency<T> {
  value: T
  delay: number

  constructor(value: T, delay: number) {
    this.value = value
    this.delay = delay
  }
}

export function debounceDependency<T>(
  value: T,
  delay: number
): DebouncedDependency<T> | T {
  if (delay <= 0) {
    return value
  }
  return new DebouncedDependency(value, delay)
}

export function debounceHookWithoutDependencies<
  Args extends unknown[],
  HookReturnType,
>(
  hook: (...args: Args) => HookReturnType
): (...args: [...Args, DebouncedDependencyList]) => HookReturnType {
  return (...args: [...Args, DebouncedDependencyList]) => {
    const inputHookParams = args.slice(0, args.length - 1) as Args
    const hookParamsRef = useRef(inputHookParams)
    useEffect(() => {
      hookParamsRef.current = inputHookParams
    }, [inputHookParams])

    const [hookParams, setHookParams] = useState(inputHookParams)
    function updateHookParams() {
      setHookParams(hookParamsRef.current)
    }

    const debounceTimeoutRef = useRef<
      | {
          dependencyIndex: number
          nextTimeToRun: number
          timeout: NodeJS.Timeout
        }
      | undefined
    >(undefined)
    function clearDebounceTimeout() {
      if (typeof debounceTimeoutRef.current !== 'undefined') {
        clearTimeout(debounceTimeoutRef.current.timeout)
        debounceTimeoutRef.current = undefined
      }
    }

    const debouncedDependencies = args.at(-1) as DebouncedDependencyList
    const dependencies = normalizeDependencies(debouncedDependencies)
    const lastDependenciesRef = useRef<DependencyList>(dependencies)
    useEffect(
      () => {
        let minDelay = Infinity
        let delayedDependencyIndex = -1
        for (let i = 0; i < lastDependenciesRef.current.length; i++) {
          if (dependencies[i] !== lastDependenciesRef.current[i]) {
            const curDependency = debouncedDependencies[i]
            if (curDependency instanceof DebouncedDependency) {
              if (curDependency.delay < minDelay) {
                minDelay = curDependency.delay
                delayedDependencyIndex = i
              }
            } else {
              minDelay = 0
              delayedDependencyIndex = -1
              break
            }
          }
        }
        lastDependenciesRef.current = dependencies

        if (minDelay === Infinity) {
          return
        }

        if (minDelay === 0) {
          // instant update
          clearDebounceTimeout()
          updateHookParams()
          return
        }

        if (
          debounceTimeoutRef.current &&
          debounceTimeoutRef.current.dependencyIndex !=
            delayedDependencyIndex &&
          debounceTimeoutRef.current.nextTimeToRun <
            new Date().getTime() + minDelay
        ) {
          // An existing delay from another dependency is already running
          return
        }

        if (
          debounceTimeoutRef.current &&
          debounceTimeoutRef.current.dependencyIndex == delayedDependencyIndex
        ) {
          // An existing delay from the current dependency is already running
          clearDebounceTimeout()
        }

        const timeout = setTimeout(() => {
          clearDebounceTimeout()
          updateHookParams()
        }, minDelay)

        debounceTimeoutRef.current = {
          dependencyIndex: delayedDependencyIndex,
          nextTimeToRun: new Date().getTime() + minDelay,
          timeout,
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dependencies
    )

    return hook(...hookParams)
  }
}

export function debounceHook<Args extends unknown[], HookReturnType>(
  hook: (...args: Args) => HookReturnType,
  dependencies: (...args: Args) => DebouncedDependencyList
): (...args: Args) => HookReturnType {
  const debouncedHook = debounceHookWithoutDependencies(hook)
  return (...args: Args) => {
    return debouncedHook(...args, dependencies(...args))
  }
}

function normalizeDependencies(
  debouncedDependencies: DebouncedDependencyList
): DependencyList {
  return debouncedDependencies.map((dep) =>
    dep instanceof DebouncedDependency ? dep.value : dep
  )
}
