import { useState } from 'react'

import { Button } from '@/core/components/common/Button'
import { Card } from '@/core/components/common/Card'
import { formatCurrency } from '@/core/utils/formatCurrency'

import { useTradingSignals } from '../../hooks/useTradingSignals'
import { UnlockTradingSignalsDialog } from './UnlockTradingSignalsDialog'

import { appConfig } from '@/config'

export const TradingSignalsCard = () => {
  const { isPaid, pay, price } = useTradingSignals()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Card className="space-y-4">
      <h4>Make the right decisions with our AI trading signals</h4>
      {isPaid ? (
        <p>
          There is a buy signal for ETH/USDT at $1,620. The system suggests
          setting a take profit target at $1,680 and a stop loss at $1,590 to
          manage risk. Zark.ai’s algorithm, which has a historical accuracy of
          68%, identifies favorable conditions for upward movement in this
          trade. This signal is based on recent market trends and key indicators
          that point towards a potential price increase in the short term.
        </p>
      ) : (
        <p className="blur-sm">
          Unlock AI trading signals to get real-time updates about important
          market developments for tokens
        </p>
      )}
      {!isPaid && (
        <div>
          <Button className="w-full" onClick={() => setIsOpen(true)}>
            Unlock for {formatCurrency(String(price))} {appConfig.currency}
          </Button>
          <UnlockTradingSignalsDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            price={price}
            pay={pay}
          />
        </div>
      )}
      <div className="bg-gray-800 rounded-lg p-3 text-center">
        Powered by <span className="uppercase font-bold">Zark X ai</span>
      </div>
    </Card>
  )
}
