import {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
  RefObject,
  useEffect,
  useId,
  useRef,
} from 'react'
import { cva } from 'class-variance-authority'

import { getAriaLabel } from '@/core/utils/aria'
import { cn } from '@/core/utils/classNames'

const BUTTON_VARIANTS_CONFIG = {
  variants: {
    color: {
      default: 'text-white text-primary-foreground',
    },
    size: {
      default: 'h-10 px-2 py-2',
    },
  },
  defaultVariants: {
    color: 'default',
    size: 'default',
  },
} as const

const buttonVariants = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'whitespace-nowrap',
    'text-sm',
    'font-medium',
    'transition-colors',
    'focus-visible:outline-none',
    'focus-visible:ring-1',
    'focus-visible:ring-ring',
  ],
  BUTTON_VARIANTS_CONFIG
)

const CUSTOM_LABEL_CLASSNAMES: Record<
  keyof (typeof BUTTON_VARIANTS_CONFIG)['variants']['size'],
  string
> = {
  default: 'opacity-80',
}

const CUSTOM_LABEL_STYLES: Record<
  keyof (typeof BUTTON_VARIANTS_CONFIG)['variants']['size'],
  CSSProperties
> = {
  default: {
    transform: 'translateY(-55%) scale(0.7)',
  },
}

const CUSTOM_BUTTON_ID = Symbol('Custom Button ID')

type ButtonInfo = {
  id: string
  label: ReactNode
  'aria-label'?: string | ((isActive: boolean) => string)
}

type CustomButtonInfo = Omit<ButtonInfo, 'id'> & {
  id: typeof CUSTOM_BUTTON_ID
}

interface CustomFieldConfig {
  label: ReactNode
  'aria-label'?: string | ((isActive: boolean) => string)
  inputContainerProps?: HTMLAttributes<HTMLDivElement>
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>
  transformInput?: (value: string) => string
  detransformInput?: (textInputValue: string) => string
}

type CustomValue = {
  value: string
  custom?: boolean
}

type BaseButtonToggleProps = {
  buttons: ButtonInfo[]
  onChange?: (value: string, method: 'preset' | 'custom') => void
  color?: keyof (typeof BUTTON_VARIANTS_CONFIG)['variants']['color']
  size?: keyof (typeof BUTTON_VARIANTS_CONFIG)['variants']['size']
  className?: string
}

export type ButtonToggleProps =
  | (BaseButtonToggleProps & {
      value?: string
      custom?: undefined
    })
  | (BaseButtonToggleProps & {
      value?: string | CustomValue
      custom?: CustomFieldConfig
    })

const ButtonToggle = forwardRef<HTMLDivElement, ButtonToggleProps>(
  (props, ref) => {
    const {
      buttons,
      value,
      onChange,
      color = BUTTON_VARIANTS_CONFIG.defaultVariants.color,
      size = BUTTON_VARIANTS_CONFIG.defaultVariants.size,
      className,
      custom,
    } = props

    const buttonContainerRef = useRef<HTMLDivElement>(null)

    const buttonsWithCustom = [
      ...buttons,
      ...(custom
        ? [
            {
              id: CUSTOM_BUTTON_ID,
              label: custom.label,
              'aria-label': custom['aria-label'],
            } satisfies CustomButtonInfo,
          ]
        : []),
    ]

    const buttonsDep = JSON.stringify(buttonsWithCustom)

    const selectedIndex =
      typeof value === 'undefined'
        ? -1
        : buttonsWithCustom.findIndex((button) =>
            typeof value === 'string'
              ? button.id === value
              : (!value.custom && button.id === value.value) ||
                (value.custom && button.id === CUSTOM_BUTTON_ID)
          )

    const { thumbRef } = useThumb({
      buttonContainerRef,
      buttonsDep,
      selectedIndex,
    })

    const { buttonElem } = useCustomButtonElem({
      value,
      custom,
      buttonCount: buttons.length,
      color,
      size,
      onChange,
    })

    return (
      <div
        ref={ref}
        className={cn(
          'rounded-full overflow-hidden bg-[#222730] relative',
          className
        )}
      >
        <div
          ref={thumbRef}
          className={cn(
            'bg-secondary-400',
            'absolute',
            'h-full',
            'z-0',
            "before:content-[''] before:bg-secondary-400 before:absolute before:top-0 before:bottom-0 before:right-[100%] before:aspect-[1/2] before:rounded-l-[100vmax]",
            "after:content-[''] after:bg-secondary-400 after:absolute after:top-0 after:bottom-0 after:left-[100%] after:aspect-[1/2] after:rounded-r-[100vmax]"
          )}
        />
        <div
          ref={buttonContainerRef}
          className="grid relative"
          style={{
            gridTemplateColumns: `repeat(${buttonsWithCustom.length}, auto)`,
          }}
        >
          {buttons.map((button, i) => {
            const isActive =
              typeof value === 'string'
                ? value === button.id
                : value?.value === button.id && !value.custom
            return (
              <button
                key={button.id}
                data-index={i}
                className={cn(buttonVariants({ color, size }), 'flex-grow')}
                aria-label={getAriaLabel(
                  button['aria-label'],
                  button.label,
                  isActive,
                  (label, isActive) => (isActive ? label : `Activate ${label}`)
                )}
                disabled={isActive}
                onClick={() => onChange?.(button.id, 'preset')}
                style={{
                  gridRow: '1 / 1',
                  gridColumnStart: i + 1,
                }}
              >
                {button.label}
              </button>
            )
          })}
          {buttonElem}
        </div>
      </div>
    )
  }
)
ButtonToggle.displayName = 'ButtonToggle'

export default ButtonToggle

interface UseThumbConfig {
  buttonContainerRef: RefObject<HTMLDivElement>
  buttonsDep: string
  selectedIndex: number
}

interface UseThumbReturnType {
  thumbRef: RefObject<HTMLDivElement>
}

function useThumb(config: UseThumbConfig): UseThumbReturnType {
  const { buttonContainerRef, buttonsDep, selectedIndex } = config

  const thumbRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const buttonContainer = buttonContainerRef.current
    const thumbElem = thumbRef.current
    if (!buttonContainer || !thumbElem) {
      return
    }

    if (selectedIndex === -1) {
      thumbRef.current.classList.toggle('opacity-0', true)
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      const buttonContainerHeight =
        buttonContainer.getBoundingClientRect().height

      const selectedButton = buttonContainer.querySelector(
        `*[data-index="${selectedIndex}"]`
      ) as HTMLButtonElement
      const x = selectedButton.offsetLeft + buttonContainerHeight / 2
      const width = Math.max(
        selectedButton.offsetWidth - buttonContainerHeight,
        0
      )
      thumbElem.style.width = `${width}px`
      thumbElem.style.transform = `translateX(${x}px)`
      thumbElem.classList.toggle('opacity-0', false)
      if (!thumbElem.classList.contains('transition-[transform,width]')) {
        // only add animations after first render
        requestAnimationFrame(() => {
          thumbElem.classList.add('transition-[transform,width]')
        })
      }
    })

    resizeObserver.observe(buttonContainerRef.current)

    // Disable lint check on the dependency array because `buttonsDep`
    // will encompass changes to the buttons variable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, buttonsDep])

  return {
    thumbRef,
  }
}

interface UseCustomInputElemConfig {
  value: string | CustomValue | undefined
  custom: CustomFieldConfig | undefined
  buttonCount: number // number of buttons excluding the custom button
  color: keyof (typeof BUTTON_VARIANTS_CONFIG)['variants']['color']
  size: keyof (typeof BUTTON_VARIANTS_CONFIG)['variants']['size']
  onChange?: (value: string, method: 'preset' | 'custom') => void
}

interface UseCustomInputElemReturnType {
  buttonElem: ReactNode
}

function useCustomButtonElem(
  config: UseCustomInputElemConfig
): UseCustomInputElemReturnType {
  const { buttonCount, value, custom, color, size, onChange } = config

  const {
    transformInput = (v: string) => v,
    detransformInput = (v: string) => v,
  } = custom ?? {}
  const {
    className: inputClassName,
    onChange: inputOnChange,
    defaultValue: inputDefaultValue,
    ...inputProps
  } = custom?.inputProps ?? {}
  const { className: inputContainerClassName, ...inputContainerProps } =
    custom?.inputContainerProps ?? {}

  const labelId = useId()
  const inputContainerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const lastCustomValueInputRef = useRef(inputDefaultValue ?? '')
  const customInputValue =
    typeof value === 'undefined' || typeof value === 'string' || !value.custom
      ? lastCustomValueInputRef.current
      : value.value

  const isActive =
    typeof value !== 'undefined' && typeof value !== 'string' && !!value.custom

  if (!custom) {
    return {
      buttonElem: null,
    }
  }

  const buttonElem = (
    <div
      className="flex-grow relative"
      style={{
        gridRow: '1 / 1',
        gridColumnStart: '-2',
      }}
      data-index={buttonCount}
      onClick={() => inputRef.current?.focus()}
    >
      <button
        className={cn(buttonVariants({ color, size }), 'w-full')}
        aria-label={getAriaLabel(
          custom['aria-label'],
          custom.label,
          isActive,
          (label, isActive) => (isActive ? label : `Activate ${label}`)
        )}
        disabled={isActive}
        onClick={() => onChange?.(customInputValue as string, 'custom')}
      >
        <label
          id={labelId}
          className={cn(
            'transition-[transform,opacity] origin-top',
            isActive && CUSTOM_LABEL_CLASSNAMES[size]
          )}
          style={isActive ? CUSTOM_LABEL_STYLES[size] : undefined}
        >
          {custom.label}
        </label>
      </button>
      <div
        ref={inputContainerRef}
        className={cn(
          'absolute inset-0 transition-[opacity] pointer-events-none',
          BUTTON_VARIANTS_CONFIG.variants.size[size],
          !isActive && 'opacity-0',
          inputContainerClassName
        )}
        {...inputContainerProps}
      >
        <input
          ref={inputRef}
          aria-labelledby={labelId}
          className={cn(
            'relative bg-transparent text-center w-full',
            isActive ? 'pointer-events-auto' : 'pointer-events-none',
            inputClassName
          )}
          value={transformInput(customInputValue as string)}
          onChange={(event) => {
            inputOnChange?.(event)
            if (event.defaultPrevented) {
              return
            }
            lastCustomValueInputRef.current = event.currentTarget.value
            onChange?.(detransformInput(event.currentTarget.value), 'custom')
          }}
          {...inputProps}
        />
      </div>
    </div>
  )

  return {
    buttonElem,
  }
}
