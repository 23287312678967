import { Outlet } from 'react-router-dom'

import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { OfflineIndicator } from '../../components/OfflineIndicator'
import { UpdateDetector } from '../../components/UpdateDetector'
import { useSubheaderSetup } from './hooks/useSubheader'

export const MainLayout = () => {
  const { containerRef: subheaderContainerRef, SubheaderProvider } =
    useSubheaderSetup<HTMLDivElement>()

  return (
    <div className="flex flex-col h-full min-h-[100vh] w-full bg-gradient-to-b from-[#282534] to-[#131117] bg-fixed">
      <header className="z-40 flex w-full top-0 grow-1 sticky transform-gpu bg-[#272531]">
        <div className="w-full h-full relative overflow-hidden safe-area-top">
          <OfflineIndicator />
          <UpdateDetector />
          <div className="pt-2 relative z-40">
            <Header />
            <div ref={subheaderContainerRef} />
          </div>
        </div>
      </header>
      <main className="flex flex-auto h-full w-full">
        <div className="flex justify-center max-w-6xl mx-auto px-4 h-auto w-full">
          <SubheaderProvider>
            <Outlet />
          </SubheaderProvider>
        </div>
      </main>
      <footer className="z-10">
        <Footer />
      </footer>
    </div>
  )
}
