import { cva } from 'class-variance-authority'

const BUTTON_VARIANTS_CONFIG = {
  variants: {
    variant: {
      default:
        'bg-secondary text-white text-primary-foreground shadow hover:bg-secondary/90',
      destructive:
        'bg-danger text-destructive-foreground shadow-sm hover:bg-destructive/90',
      outline:
        'border border-input border-secondary text-white bg-background shadow-sm hover:bg-transparent/10',
      ghost: 'text-white hover:bg-secondary/50',
      iconGhost: 'text-white',
      underline: 'underline',
      link: 'text-cool-gray-100 underline-offset-4 hover:underline',
      'secondary-link': 'text-secondary-400 uppercase',
    },
    size: {
      default: 'h-10 px-8 py-2',
      sm: 'h-8 rounded-md px-3 text-xs',
      md: 'h-8 rounded-md px-3 text-md',
      lg: 'h-10 rounded-md px-8',
      icon: 'h-9 w-9',
      'secondary-link': 'w-auto h-auto px-0 py-0',
    },
    hideDisabled: {
      true: '',
      false: 'disabled:opacity-50',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
    hideDisabled: false,
  },
} as const

export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none',
  BUTTON_VARIANTS_CONFIG
)

export type ButtonStyleProps = {
  variant?: keyof (typeof BUTTON_VARIANTS_CONFIG)['variants']['variant']
  size?: keyof (typeof BUTTON_VARIANTS_CONFIG)['variants']['size']
}
