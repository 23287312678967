import { FC, useState } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import { TokenAddress, ZarklabToken } from '@/core/types/token'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/core/components/common/Dialog'
import { useAllZarklabTokens } from '@/core/hooks/useAllZarklabTokens'
import { useChainFromRoute } from '@/core/hooks/useChain'

import { SearchInput } from '../../../../../core/components/SearchInput'
import { ChainIndicator } from './ChainIndicator'
import { TokenList, TokenListProps } from './TokenList'

type TokenSelectModalProps = {
  currentTokenAddress?: TokenAddress | undefined
  value?: TokenAddress | undefined
  onChange?: TokenListProps['onChange']
  isOpen: boolean
  setIsOpenDialog: (isOpen: boolean) => void
}

export const TokenSelectModal: FC<TokenSelectModalProps> = ({
  currentTokenAddress,
  value,
  onChange,
  isOpen,
  setIsOpenDialog,
}) => {
  const { chainId } = useChainFromRoute()

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSetSearchQuery = useDebounceCallback(setSearchQuery, 500)

  const { tokens, hasNextPage, fetchNextPage, isFetching } =
    useAllZarklabTokens({ chainId, searchQuery })

  const filteredTokens = tokens.filter(
    (item: ZarklabToken) =>
      item.address !== value && item.address !== currentTokenAddress
  )

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpenDialog}>
      <DialogContent
        aria-describedby={''}
        className={
          'md:w-[398px] bg-cool-gray-900 border border-cool-gray-850 rounded-md shadow-lg p-6'
        }
        dialogCloseClassName="right-5 top-5 md:top-5"
      >
        <DialogHeader>
          <DialogTitle className="flex items-center space-x-2">
            <span className="text-white">Select a token</span>
          </DialogTitle>
        </DialogHeader>
        <div className="flex gap-2">
          <SearchInput
            className="w-full md:w-auto flex-grow"
            value={searchQuery}
            onChange={debouncedSetSearchQuery}
          />
          <ChainIndicator />
        </div>
        <TokenList
          tokenFetcher={{
            items: filteredTokens,
            hasNextPage,
            fetchNextPage,
            isFetching,
          }}
          showMajorTokens={!searchQuery}
          showRecentSearches={!searchQuery}
          value={value}
          onChange={onChange}
        />
      </DialogContent>
    </Dialog>
  )
}
